import { authHeaders, del, get, post, put } from "../../../utils/fetch";
import { Hosts, RequestState } from "../../../constants";

// Actions
export const FETCH_CHATBOT_TOOLS = 'FETCH_CHATBOT_TOOLS';
export const CREATE_CHATBOT_TOOL = 'CREATE_CHATBOT_TOOL';
export const UPDATE_CHATBOT_TOOL = 'UPDATE_CHATBOT_TOOL';
export const DELETE_CHATBOT_TOOL = 'DELETE_CHATBOT_TOOL';
export const FETCH_CHATBOT_TOOL = 'FETCH_CHATBOT_TOOL';

// Add selectors at the top level of the file
export const selectChatbotTools = (state, chatbotId) => {
    return state?.models?.chatbotTools?.[chatbotId] || [];
};

const ChatbotTools = {
    actions: {
        // List tools
        getChatbotTools: (projectId, chatbotId, skip = 0, limit = 100) => async (dispatch) => {
            let result = await get({
                host: Hosts.EDGE_CLOUD_CONTROLLER_API,
                url: `/chatbot/${chatbotId}/tools`,
                params: { project_id: projectId, skip, limit },
                headers: authHeaders(),
                action: FETCH_CHATBOT_TOOLS,
                dispatch,
                data: { chatbotId }
            });
            
            // Assuming the API returns { items: [], total: number }
            return {
                items: result.body,
            };
        },

        // Create tool
        createChatbotTool: (chatbotId, body) => async (dispatch) => {
            let result = await post({
                host: Hosts.EDGE_CLOUD_CONTROLLER_API,
                url: `/chatbot/${chatbotId}/tools`,
                headers: authHeaders(),
                action: CREATE_CHATBOT_TOOL,
                dispatch,
                body: body,
                data: { chatbotId }
            });
            return result.body;
        },

        // Get single tool
        getChatbotTool: (projectId, chatbotId, toolId) => async (dispatch) => {
            let result = await get({
                host: Hosts.EDGE_CLOUD_CONTROLLER_API,
                url: `/chatbot/${chatbotId}/tools/${toolId}`,
                params: { project_id: projectId },
                headers: authHeaders(),
                action: FETCH_CHATBOT_TOOL,
                dispatch,
                data: { chatbotId }
            });
            return result.body;
        },

        // Update tool
        updateChatbotTool: (chatbotId, toolId, body) => async (dispatch) => {
            let result = await put({
                host: Hosts.EDGE_CLOUD_CONTROLLER_API,
                url: `/chatbot/${chatbotId}/tools/${toolId}`,
                headers: authHeaders(),
                action: UPDATE_CHATBOT_TOOL,
                dispatch,
                body: body,
                data: { chatbotId }
            });
            return result.body;
        },

        // Delete tool
        deleteChatbotTool: (projectId, chatbotId, toolId) => async (dispatch) => {
            let result = await del({
                host: Hosts.EDGE_CLOUD_CONTROLLER_API,
                url: `/chatbot/${chatbotId}/tools/${toolId}`,
                params: { project_id: projectId },
                headers: authHeaders(),
                action: DELETE_CHATBOT_TOOL,
                dispatch,
                data: { chatbotId, toolId }
            });
            return result.body;
        }
    },

    spec: {
        chatbotTools: {}
    },

    modelReducer: (state, type, body, action) => {
        if (action.url && action.result !== RequestState.SUCCESS)
            return state;

        if (type === FETCH_CHATBOT_TOOLS) {
            const { chatbotId } = action.data;
            const { skip = 0 } = action.params;
            
            return {
                ...state,
                chatbotTools: {
                    ...state.chatbotTools,
                    [chatbotId]: skip === 0 
                        ? body 
                        : [...(state.chatbotTools[chatbotId] || []), ...body]
                }
            }
        }

        if (type === CREATE_CHATBOT_TOOL) {
            const { chatbotId } = action.data;
            return {
                ...state,
                chatbotTools: {
                    ...state.chatbotTools,
                    [chatbotId]: [
                        body,
                        ...(state.chatbotTools[chatbotId] || [])
                    ]
                }
            }
        }

        if (type === UPDATE_CHATBOT_TOOL) {
            const { chatbotId } = action.data;
            return {
                ...state,
                chatbotTools: {
                    ...state.chatbotTools,
                    [chatbotId]: (state.chatbotTools[chatbotId] || []).map(tool => 
                        tool.id === body.id ? body : tool
                    )
                }
            }
        }

        if (type === DELETE_CHATBOT_TOOL) {
            const { chatbotId, toolId } = action.data;
            return {
                ...state,
                chatbotTools: {
                    ...state.chatbotTools,
                    [chatbotId]: (state.chatbotTools[chatbotId] || []).filter(tool => 
                        tool.id !== toolId
                    )
                }
            }
        }

        return state;
    }
}

export default ChatbotTools;
