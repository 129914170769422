import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import Ai from '../../store/models/Ai'
import Loader from '../Loader';
import { Button } from '../Button';
import { DailyChart } from '../Chart';
import { selectMobileLayout } from '../../store/UIState';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Pagination } from '../Pagination';
import { getDatesBetween } from '../../utils';
import classNames from 'classnames';
import { AiTabs, AiTab } from '../AiTabs';


export const RagStatsTab = ({chatbot}) => {
    const { projectId, id } = useParams();
    const dispatch = useDispatch();
    const [selectedStatsTab, setSelectedStatsTab] = useState('chart');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
  
    const onTabChanged = (tab) => {
      setSelectedStatsTab(tab);
    }
  
    return <div className='AiServicePage__tab-content'>
      <div className='RagChatbotDetailPage stats'>
        <AiTabs>
          <AiTab isSelected={(selectedStatsTab === 'chart')}
            onClick={() => onTabChanged('chart')}>
            Chart
          </AiTab>
          <AiTab isSelected={(selectedStatsTab === 'prompts')}
            onClick={() => onTabChanged('prompts')}>
            Prompts
          </AiTab>
        </AiTabs>

        {chatbot && chatbot.ui.disable_tracking && <div className='RagChatbotDetailPage__analytics-disabled'>
          <div className='RagChatbotDetailPage__analytics-disabled--title'>Analytics are disabled for this chatbot</div>
          <div className='RagChatbotDetailPage__analytics-disabled--description'>Go to the settings tab to enable analytics</div>
        </div>}
        
        {selectedStatsTab === 'chart' && <ChartTab />}
        {selectedStatsTab === 'prompts' && <PromptsTab />}
      </div>
    </div>
  }
  
  const ChartTab = () => {
    const { projectId, id } = useParams();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [chartData, setChartData] = useState({});
  
    const currentDate = new Date();
    const iniStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate());
    const [startDate, setStartDate] = useState(iniStartDate);
    const [endDate, setEndDate] = useState(currentDate);
    const [isChanged, setIsChanged] = useState(false);
  
    const isMobile = useSelector(state => selectMobileLayout(state));
    const screenWidth = window.innerWidth;
    const chartWidth = isMobile ? screenWidth - 100 : 750;
  
    useEffect(() => {
      fetchChartData();
    }, [id])
  
    const fetchChartData = async () => {
      try {
        setLoading(true);
        let res = await dispatch(Ai.actions.fetchChatbotChartData(id, projectId));
        let sDate = moment(startDate).format('YYYY-MM-DD')
        let eDate = moment(endDate).format('YYYY-MM-DD')
        let dates = getDatesBetween(sDate, eDate);
        let arr = [];
        dates.forEach(date => {
          let newObj = { date: date };
  
          arr.push(newObj)
        });
  
        let data;
        if (res) {
          data = {};
  
          data.clients_by_day = dates.map((date) => {
            const [year, month, day] = date.split("-").map(Number);
            const match = (_.get(res, 'clients_by_day') || []).find(
              (item) => item.year === year && item.month === month && item.day === day
            );
            return {
              date,
              result: match ? match.count : 0,
            };
          });
          data.prompts_count = dates.map((date) => {
            const [year, month, day] = date.split("-").map(Number);
            const match = (_.get(res, 'prompts_count') || []).find(
              (item) => item.year === year && item.month === month && item.day === day
            );
            return {
              date,
              result: match ? match.count : 0,
            };
          });
        }
        setChartData(data);
      } catch (e) {
        setError(e.message);
      } finally {
        setLoading(false);
      }
    }
  
    return <div>
      <div className='RagChatbotDetailPage__row'>
        <div className="RagChatbotDetailPage__datePicker">
          <div className="RagChatbotDetailPage__datePicker--label">Start Date</div>
          <DatePicker selected={startDate}
            onChange={(v) => {
              setStartDate(v);
            }}
            disabled={loading}
            maxDate={endDate} />
        </div>
        <div className="RagChatbotDetailPage__datePicker">
          <div className="RagChatbotDetailPage__datePicker--label">End Date</div>
          <DatePicker selected={endDate}
            onChange={(v) => {
              setEndDate(v)
            }}
            disabled={loading}
            minDate={startDate}
            maxDate={currentDate} />
        </div>
        <Button className={"RagChatbotDetailPage__datePicker--button"}
          color={"green"}
          loading={loading}
          title={"Update Chart"}
          onClick={fetchChartData} />
      </div>
      {loading ? <Loader color='green' size='large' /> :
        chartData ?
          <>
            <DailyChart width={chartWidth} data={chartData.clients_by_day || []}
              legendName={'DAU'} />
            <DailyChart width={chartWidth} data={chartData.prompts_count || []}
              legendName={'Daily Prompts Count'} />
          </> : <div className='EmptyState'>
            <div className={'EmptyState__title'}>No data available in the selected date range. Please adjust your date range.</div>
          </div>}
    </div>
  }
  
  const PromptsTab = () => {
    const { projectId, id } = useParams();
    const dispatch = useDispatch();
    const isMobile = useSelector(state => selectMobileLayout(state));
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [prompts, setPrompts] = useState();
    const [curPrompts, setCurPrompts] = useState();
    const [curPage, setCurPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const currentDate = new Date();
    const iniStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate());
    const [startDate, setStartDate] = useState(iniStartDate);
    const [endDate, setEndDate] = useState(currentDate);
    const [isChanged, setIsChanged] = useState(false);
    const MAX_RECORDS_PER_PAGE = 30;
  
    useEffect(() => {
      if (!prompts) {
        fetchPrompts();
      }
    }, [id, prompts])
  
    const fetchPrompts = async () => {
      try {
        setLoading(true);
        let sDate = moment(startDate).format('YYYY-MM-DD')
        let eDate = moment(endDate).format('YYYY-MM-DD')
        let res = await dispatch(Ai.actions.fetchChatbotPrompts(id, projectId, { start_timestamp: sDate, end_timestamp: eDate }));
        setPrompts(res);
        let cp = res.slice(0, MAX_RECORDS_PER_PAGE)
        setCurPrompts(cp);
        setTotalPages(Math.ceil(res.length / MAX_RECORDS_PER_PAGE));
        setCurPage(1);
      } catch (e) {
        setError(e.message);
      } finally {
        setLoading(false);
        if (isChanged) setIsChanged(false);
      }
    }
  
    const handleDownload = async () => {
      function convertToCSV(objArray) {
        var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        var str = '';
  
        var headers = Object.keys(array[0]);
        str += headers.map(header => `"${header}"`).join(',') + '\r\n';
  
        for (var i = 0; i < array.length; i++) {
          var row = headers
            .map(header => {
              let value = array[i][header];
              if (typeof value === 'string') {
                value = value.replace(/"/g, '""');
                return `"${value}"`;
              }
              return value;
            })
            .join(',');
  
          str += row + '\r\n';
        }
        return str;
      }
  
      var csv = convertToCSV(prompts.map(p => ({ prompts: p.question, completion: p.answer })));
  
      var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('href', url);
      a.setAttribute('download', `${id}_prompts.csv`);
      a.click();
      window.URL.revokeObjectURL(url);
    }
  
    const handlePageChange = (page) => {
      setCurPage(page);
      setCurPrompts(prompts.slice(MAX_RECORDS_PER_PAGE * (page - 1), MAX_RECORDS_PER_PAGE * page));
    }
  
    return <>
      <div className='RagChatbotDetailPage__row'>
        <div className="RagChatbotDetailPage__datePicker">
          <div className="RagChatbotDetailPage__datePicker--label">Start Date</div>
          <DatePicker selected={startDate}
            onChange={(v) => {
              setStartDate(v);
              if (!isChanged) setIsChanged(true)
            }}
            disabled={loading}
            maxDate={endDate} />
        </div>
        <div className="RagChatbotDetailPage__datePicker">
          <div className="RagChatbotDetailPage__datePicker--label">End Date</div>
          <DatePicker selected={endDate}
            onChange={(v) => {
              setEndDate(v)
              if (!isChanged) setIsChanged(true)
            }}
            disabled={loading}
            minDate={startDate}
            maxDate={currentDate} />
        </div>
        <Button className={"RagChatbotDetailPage__datePicker--button"}
          color={"green"}
          loading={loading}
          title={isChanged ? "Update Data" : "Download All Data"}
          onClick={isChanged ? fetchPrompts : handleDownload}
          size={isMobile ? 'large' : 'medium'} />
      </div>
      {prompts && prompts.length > 0 && <div className='RagChatbotDetailPage__prompt-table'>
        <div className='RagChatbotDetailPage__prompt-table--header-row'>
          <div className='RagChatbotDetailPage__prompt-table--cell question'>Prompt</div>
          <div className='RagChatbotDetailPage__prompt-table--cell answer'>Completion</div>
        </div>
        {curPrompts && curPrompts.map((prompt, i) => {
          return <PromptTableRow prompt={prompt} i={i} key={i} />
        })}
        {/* {curPrompts && curPrompts.map((prompt, i) => {
          return <div className='RagChatbotDetailPage__prompt-table--row' key={i}>
            <div className='RagChatbotDetailPage__prompt-table--cell ip' title={prompt.client_ip}>
              {prompt.client_ip}
            </div>
            <div className='RagChatbotDetailPage__prompt-table--cell question' title={prompt.question}>
              {prompt.question}
            </div>
            <div className='RagChatbotDetailPage__prompt-table--cell answer' title={prompt.answer}>
              {prompt.answer}
            </div>
          </div>
        })} */}
        <Pagination
          size={'lg'}
          currentPage={curPage}
          totalPages={totalPages}
          onPageChange={handlePageChange} />
      </div>}
      {prompts && prompts.length === 0 && <div className='EmptyState'>
        <div className={'EmptyState__title'}>No data available in the selected date range. Please adjust your date range.</div>
      </div>}
    </>
  }
  
  const PromptTableRow = ({ prompt, i }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const questionRef = useRef(null);
    const answerRef = useRef(null);
    const [isTruncated, setIsTruncated] = useState(false);
  
    // Check if content is truncated
    useEffect(() => {
      const isQuestionTruncated =
        questionRef.current.scrollWidth > questionRef.current.clientWidth;
      const isAnswerTruncated =
        answerRef.current.scrollWidth > answerRef.current.clientWidth;
  
      setIsTruncated(isQuestionTruncated || isAnswerTruncated);
    }, [prompt.question, prompt.answer, prompt.client_ip]);
  
    return (
      <div
        className={`RagChatbotDetailPage__prompt-table--row ${isExpanded ? 'expanded' : ''}`}
        key={i}
      >
        <div
          className={classNames('RagChatbotDetailPage__prompt-table--cell question', { 'expand': isExpanded })}
          title={prompt.question}
          ref={questionRef}>
          {prompt.question}
        </div>
        <div
          className={classNames('RagChatbotDetailPage__prompt-table--cell answer', { 'expand': isExpanded })}
          title={prompt.answer}
          ref={answerRef}>
          {prompt.answer}
        </div>
        {isTruncated && (
          <div className={`expand-button ${isExpanded ? 'arrow-up' : 'arrow-down'}`} onClick={() => setIsExpanded(!isExpanded)}></div>
        )}
      </div>
    );
  };

