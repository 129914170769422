import React from "react";
import classNames from "classnames";

export default function Loader({size = 'medium', color = 'white', position = 'center', style}) {
  return (
    <div className={classNames('Loader', `Loader--${size}`, `Loader--${color}`, `Loader--${position}`)}
         style={style}
    />
  );
}

