import React, { forwardRef } from 'react';
import cx from 'classnames';

export const Toggle = forwardRef(({ 
  className,
  checked = false,
  onChange,
  disabled = false,
  ...props
}, ref) => {
  return (
    <label className={cx('Toggle', className, { disabled })}>
      <input
        type="checkbox"
        ref={ref}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        {...props}
      />
      <span className="Toggle__slider" />
    </label>
  );
});

Toggle.displayName = 'Toggle'; 