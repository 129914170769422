import React from 'react';

export const Checkbox = ({ label, checked, onChange, tooltip }) => {
  return (
    <div className='Checkbox'>
      <label>
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
        />
        {label}
      </label>
    </div>
  );
}; 