import React, { useState } from "react";
import { Button } from "../Button";
import Loader from "../Loader";
import { SendIcon } from "../Svg";

export const AudioGenerator = ({ onAudioGenerated, isGenerating, setIsGenerating, url, onStopGenerating }) => {
  const [prompt, setPrompt] = useState("");
  const [voiceDescription, setVoiceDescription] = useState("");
  const [error, setError] = useState(null);

  const handleGenerateAudio = async (e) => {
    e.preventDefault();
    
    if (!prompt.trim()) {
      setError("Please enter text to generate audio");
      return;
    }
    
    if (!voiceDescription.trim()) {
      setError("Please enter a voice description");
      return;
    }
    
    setError(null);
    setIsGenerating(true);
    
    const sessionHash = Math.random().toString(36).substring(2, 12);
    
    try {
      // Initiate generation
      const response = await fetch(`${url}/queue/join`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          data: [prompt, voiceDescription],
          event_data: null,
          fn_index: 2,
          trigger_id: 19,
          session_hash: sessionHash
        })
      });
      
      if (!response.ok) {
        throw new Error("Failed to start audio generation");
      }
      
      // Listen for events
      const eventSource = new EventSource(`${url}/queue/data?session_hash=${sessionHash}`);
      
      eventSource.onmessage = (e) => {
        const data = JSON.parse(e.data);
        
        if (data.msg === 'process_completed') {
          eventSource.close();
          setIsGenerating(false);
          
          if (data.success) {
            const audioUrl = `${data.output.data[0].value.url}`;
            onAudioGenerated(audioUrl);
          } else {
            setError("Failed to generate audio. Please try again.");
          }
        }
      };
      
      eventSource.onerror = () => {
        eventSource.close();
        setIsGenerating(false);
        setError("Error connecting to server. Please try again later.");
      };
      
    } catch (error) {
      console.error("Audio generation error:", error);
      setIsGenerating(false);
      setError("Failed to generate audio. Please try again.");
    }
  };

  return (
    <div className="AudioGenerator">
      {error && <div className="AudioGenerator__Error">{error}</div>}
      
      <div className="AudioGenerator__Form">
        <div className="AudioGenerator__InputGroup">
          <label htmlFor="prompt">Text to speak</label>
          <input
            id="prompt"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            placeholder="Enter text to generate audio..."
            disabled={isGenerating}
          />
        </div>
        
        <div className="AudioGenerator__InputGroup">
          <label htmlFor="voiceDescription">Voice description</label>
          <input
            type="text"
            id="voiceDescription"
            value={voiceDescription}
            onChange={(e) => setVoiceDescription(e.target.value)}
            placeholder="E.g., deep male voice, female British accent..."
            disabled={isGenerating}
          />
        </div>
        
        {isGenerating ? (
          <Button
            title="Stop Generation"
            onClick={onStopGenerating}
            className="AudioGenerator__Button"
            color="red"
          />
        ) : (
          <Button
            title="Generate Audio"
            onClick={handleGenerateAudio}
            icon={<SendIcon />}
            className="AudioGenerator__Button"
            color="green"
            disabled={!prompt.trim() || !voiceDescription.trim()}
          />
        )}
      </div>
    </div>
  );
}; 