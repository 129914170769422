import {CloseIcon} from "../Svg";
import React, {useState, useRef, useEffect} from "react";
import {toast} from "react-toastify";
import WaveSurfer from 'wavesurfer.js';

export const AudioInput = ({children, onChange, value, name, onRemove, instructions, formats, displayName, ...props}) => {
  const [dragging, setDragging] = useState(false);
  const [fileName, setFileName] = useState(displayName || '');
  const [prevValue, setPrevValue] = useState(value);
  
  const audioRef = useRef(null);
  const waveformRef = useRef(null);
  const wavesurferRef = useRef(null);

  // Update filename when displayName or value changes
  useEffect(() => {
    // If displayName is provided, use it
    if (displayName) {
      setFileName(displayName);
      return;
    }
    
    // If value changed and no displayName is provided, extract filename from value
    if (value !== prevValue) {
      setPrevValue(value);
      
      if (value) {
        if (value instanceof File) {
          setFileName(value.name);
        } else if (typeof value === 'string') {
          // For URLs, extract the filename
          const pathParts = value.split('/');
          let rawName = pathParts[pathParts.length - 1];
          
          // Remove any query parameters
          rawName = rawName.split('?')[0];
          
          try {
            // Try to decode URI components
            const cleanName = decodeURIComponent(rawName);
            setFileName(cleanName);
          } catch (e) {
            setFileName(rawName);
          }
        }
      } else {
        setFileName('');
      }
    }
  }, [value, displayName, prevValue]);

  const onAudioSelected = (e) => {
    setDragging(false);
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
    }
    onChange(file, e.target.name);
  }

  const onRemoveClick = () => {
    // First call onRemove to update parent state
    onRemove(name);
    setFileName('');
    
    // Clean up audio elements if they exist
    setTimeout(() => {
      try {
        // Stop any playing audio before removing
        if (audioRef.current) {
          audioRef.current.pause();
          audioRef.current.currentTime = 0;
        }
        
        // Clean up wavesurfer instance if it exists
        if (wavesurferRef.current) {
          if (typeof wavesurferRef.current.isPlaying === 'function' && 
              wavesurferRef.current.isPlaying()) {
            wavesurferRef.current.pause();
          }
          wavesurferRef.current.destroy();
          wavesurferRef.current = null;
        }
      } catch (error) {
        console.warn("Error cleaning up audio resources:", error);
      }
    }, 0);
  }

  // Initialize WaveSurfer when audio is loaded
  useEffect(() => {
    if (!value || !waveformRef.current) return;

    // Stop any playing audio when value changes
    if (audioRef.current) {
      try {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      } catch (error) {
        console.warn("Error resetting audio on value change:", error);
      }
    }

    // Destroy previous instance if it exists
    if (wavesurferRef.current) {
      try {
        if (typeof wavesurferRef.current.isPlaying === 'function') {
          wavesurferRef.current.pause();
        }
        wavesurferRef.current.destroy();
      } catch (error) {
        console.warn("Error destroying wavesurfer:", error);
      } finally {
        wavesurferRef.current = null;
      }
    }

    // Create WaveSurfer instance
    const wavesurfer = WaveSurfer.create({
      container: waveformRef.current,
      waveColor: '#4CAF50', // $color-green
      progressColor: '#ffffff',
      cursorColor: '#ffffff',
      barWidth: 2,
      barGap: 3,
      barRadius: 2,
      height: 60,
      cursorWidth: 2,
      responsive: true,
      normalize: true,
      backend: 'WebAudio',
    });

    // Load audio
    wavesurfer.load(value);

    // Set up event listeners
    wavesurfer.on('ready', () => {
      wavesurferRef.current = wavesurfer;
    });

    wavesurfer.on('play', () => {
      if (audioRef.current) {
        try {
          audioRef.current.play().catch(err => console.warn("Error playing audio:", err));
        } catch (error) {
          console.warn("Error playing audio:", error);
        }
      }
    });

    wavesurfer.on('pause', () => {
      if (audioRef.current) {
        try {
          audioRef.current.pause();
        } catch (error) {
          console.warn("Error pausing audio:", error);
        }
      }
    });

    wavesurfer.on('seeking', () => {
      if (audioRef.current && wavesurfer.getCurrentTime !== undefined) {
        try {
          const currentTime = wavesurfer.getCurrentTime();
          if (currentTime !== undefined) {
            audioRef.current.currentTime = currentTime;
          }
        } catch (error) {
          console.warn("Error seeking audio:", error);
        }
      }
    });

    // Sync with audio element
    if (audioRef.current) {
      const handleTimeUpdate = () => {
        if (!wavesurfer || !audioRef.current) return;
        
        try {
          if (!wavesurfer.isPlaying() && audioRef.current.currentTime > 0 && audioRef.current.duration) {
            wavesurfer.seekTo(audioRef.current.currentTime / audioRef.current.duration);
          }
        } catch (error) {
          console.warn("Error in timeupdate handler:", error);
        }
      };

      const handlePlay = () => {
        if (!wavesurfer) return;
        
        try {
          if (!wavesurfer.isPlaying()) {
            wavesurfer.play();
          }
        } catch (error) {
          console.warn("Error in play handler:", error);
        }
      };

      const handlePause = () => {
        if (!wavesurfer) return;
        
        try {
          if (wavesurfer.isPlaying()) {
            wavesurfer.pause();
          }
        } catch (error) {
          console.warn("Error in pause handler:", error);
        }
      };

      audioRef.current.addEventListener('timeupdate', handleTimeUpdate);
      audioRef.current.addEventListener('play', handlePlay);
      audioRef.current.addEventListener('pause', handlePause);
      
      // Clean up event listeners when component unmounts or value changes
      return () => {
        if (audioRef.current) {
          audioRef.current.removeEventListener('timeupdate', handleTimeUpdate);
          audioRef.current.removeEventListener('play', handlePlay);
          audioRef.current.removeEventListener('pause', handlePause);
        }
        
        if (wavesurferRef.current) {
          try {
            wavesurferRef.current.pause();
            wavesurferRef.current.destroy();
          } catch (error) {
            console.warn("Error cleaning up wavesurfer:", error);
          }
          wavesurferRef.current = null;
        }
      };
    }
  }, [value]);

  // Toggle play/pause
  const togglePlay = () => {
    if (wavesurferRef.current) {
      try {
        if (wavesurferRef.current.isPlaying()) {
          wavesurferRef.current.pause();
        } else {
          wavesurferRef.current.play();
        }
      } catch (error) {
        console.warn("Error toggling play state:", error);
      }
    }
  };

  return (
    <div className={"AudioInput"}>
      {value ?
        <>
          <div className={"AudioInput__AudioUploaded"}>
            <div className="AudioInput__AudioUploaded__Info">
              <div className="AudioInput__AudioUploaded__Filename">
                {fileName}
              </div>
              <div 
                className="AudioInput__AudioUploaded__Waveform" 
                ref={waveformRef}
                onClick={togglePlay}
              />
              <audio 
                ref={audioRef}
                controls 
                src={value} 
                className="AudioInput__AudioUploaded__Player"
                controlsList="nodownload nofullscreen noremoteplayback noplaybackrate" 
              />
            </div>
            <div className={"AudioInput__AudioUploaded__Close"} onClick={onRemoveClick}><CloseIcon/></div>
          </div>
        </>
        : <>
          <div
            className={`AudioInput__Label ${dragging ? "AudioInput__Label--dragover" : ""}`}
            onClick={() => document.querySelector(`input[name="${name}"]`).click()}
            onDragOver={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onDragEnter={() => setDragging(true)}
            onDragLeave={() => setDragging(false)}
            onDrop={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
                const file = e.dataTransfer.files[0];
                if (formats && formats.indexOf(file.type) === -1
                  || file.type.indexOf("audio/") === -1) {
                  toast.error("Invalid file type");
                } else {
                  setFileName(file.name);
                  onChange(file, name);
                }
              }
            }}
          >
            {instructions
              ? instructions
              : <>
              Drag & drop or <span
              className={"AudioInput__Label--Green"}>Choose an audio file</span> to upload
            </>}
          </div>
          <input type="file" name={name} accept={formats ? formats : "audio/*"} onChange={onAudioSelected}/></>}

      {children}
    </div>
  )
} 