import _ from 'lodash';
import moment from 'moment';
import Loader from '../components/Loader';
import { IoIosCheckmarkCircleOutline, IoIosCloseCircleOutline } from "react-icons/io";
import { Link } from "react-router-dom";
import { mergeUrl } from './url';
import { Urls, UserRoleIds } from '../constants';
import { ActiveIcon, StoppedIcon } from '../components/Svg';

export function hexToRGB(hex, alpha) {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}

export const toNumber = (num) => {
  const x = _.toNumber(num);
  return _.isNaN(x) ? 0 : x;
}

export function sleep(delay) {
  return new Promise((res, rej) => {
    let id = setTimeout(() => {
      res();
    }, delay);
  })
}

export function copyToClipboard(str) {
  //https://gist.githubusercontent.com/Chalarangelo/4ff1e8c0ec03d9294628efbae49216db/raw/cbd2d8877d4c5f2678ae1e6bb7cb903205e5eacc/copyToClipboard.js

  const el = document.createElement('textarea');  // Create a <textarea> element
  el.value = str;                                 // Set its value to the string that you want copied
  el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
  el.style.position = 'absolute';
  el.style.left = '-9999px';                      // Move outside the screen to make it invisible
  document.body.appendChild(el);                  // Append the <textarea> element to the HTML document
  const selected =
    document.getSelection().rangeCount > 0        // Check if there is any content selected previously
      ? document.getSelection().getRangeAt(0)     // Store selection if found
      : false;                                    // Mark as false to know no selection existed before
  el.select();                                    // Select the <textarea> content
  document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
  document.body.removeChild(el);                  // Remove the <textarea> element
  if (selected) {                                 // If a selection existed before copying
    document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
    document.getSelection().addRange(selected);   // Restore the original selection
  }
}

export function formatCurrency(num, length = 2) {
  return '$' + num.toFixed(length).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export function getBarCard(barName) {
  switch (barName) {
    case 'views':
    case "unique_viewers":
    case "playing_time":
      return 'Views';
    case 'playback_success_score':
    case 'playback_failure_percentage':
    case 'exits_before_video_start':
      return 'Playback Success';
    case 'startup_time_score':
    case 'video_startup_time':
    case 'player_startup_time':
      return 'Startup Time';
    case 'smoothness_score':
    case 'rebuffer_count':
      return 'Smoothness';
    case 'video_quality_score':
    case 'upscale_percentage':
      return 'Video Quality';
    case 'akamai_hits':
      return 'URL Hits'
    case 'akamai_volume':
      return 'URL Volume'
    case 'url_viewers':
      return 'URL Viewers'

    default:
      return "URL Viewers";
  }
}

export function getChartDataByBar(barName) {
  switch (barName) {
    case 'URL Viewers':
      return 'url_viewers'
    case 'URL Hits':
      return 'akamai_hits'
    case 'URL Volume':
      return 'akamai_volume'
    default:
      return "";
  }
}

export function getChartType(barName) {
  switch (barName) {
    case 'views':
    case "unique_viewers":
    case "playing_time":
    case 'playback_success_score':
    case 'playback_failure_percentage':
    case 'exits_before_video_start':
    case 'startup_time_score':
    case 'video_startup_time':
    case 'player_startup_time':
    case 'smoothness_score':
    case 'rebuffer_count':
    case 'video_quality_score':
    case 'upscale_percentage':
      return 1;
    case 'akamai_hits':
    case 'akamai_volume':
      return 2;

    default:
      // console.log(`unknown barName ${barName} in getChartType`);
      return 2;
  }
}

export function getBarByCardName(cardName) {
  switch (cardName) {
    case 'Views':
      return "views";
    case 'Overall Viewer Experience':
      return "viewer_experience_score";
    case 'Playback Success':
      return 'playback_success_score';
    case 'Startup Time':
      return 'startup_time_score';
    case 'Smoothness':
      return 'smoothness_score';
    case 'Video Quality':
      return 'video_quality_score';
    case 'URL Hits':
      return 'akamai_hits';
    case 'URL Volume':
      return 'akamai_volume';
    case 'URL Viewers':
      return 'url_viewers';

    default:
      return "";
  }
  ;
}

export function _getVMOptions(vms, needGPU) {
  return Object.keys(vms).filter(k => {
    if (needGPU) return !k.includes('vm_c')
    return true;
  }).map(k => {
    if (k.includes('vm_c'))
      return {
        value: k,
        label: `${vms[k].name} (CPU: ${vms[k].resources.cpu / 1000} Cores, Mem: ${convertUnit(vms[k].resources.mem, 'MB')}, Ephemeral storage: ${convertUnit(vms[k].resources.storage, 'GB')})`
      }
    if (k.includes('vm_g')) {
      return {
        value: k,
        label: `${vms[k].name} (CPU: ${vms[k].resources.cpu / 1000} Cores, Mem: ${convertUnit(vms[k].resources.mem, 'MB')}, Ephemeral storage: ${convertUnit(vms[k].resources.storage, 'GB')}, GPU: ${vms[k].resources['gpu_model']} x ${vms[k].resources.gpu})`
      }
    }
  })
}

export function getVMOptions(vms, suggestedVM = null) {
  return Object.keys(vms).map(k => {
    if (k.includes('vm_c'))
      return {
        value: k,
        label: `${vms[k].name} (CPU: ${vms[k].resources.cpu / 1000} Cores, Mem: ${convertUnit(vms[k].resources.mem, 'MB')}, Ephemeral storage: ${convertUnit(vms[k].resources.storage, 'GB')})${k === suggestedVM ? ' *Recommended' : ''}`,
        price: Number(vms[k].price_hour),
        name: vms[k].name
      }
    if (k.includes('vm_g')) {
      return {
        value: k,
        label: `${vms[k].name} (CPU: ${vms[k].resources.cpu / 1000} Cores, Mem: ${convertUnit(vms[k].resources.mem, 'MB')}, Ephemeral storage: ${convertUnit(vms[k].resources.storage, 'GB')}, GPU: ${vms[k].resources['gpu_model']} x ${vms[k].resources.gpu})${k === suggestedVM ? ' *Recommended' : ''}`,
        price: Number(vms[k].price_hour),
        name: vms[k].name
      }
    }
  })
}

export function getHardwareDetail(vms, k) {
  if (Object.keys(vms).length === 0) return '';
  return vms[k] ? getVmResourcesString(vms[k].resources) : 'N/A';
}

export function getVmResourcesString(resources) {
  let formattedResources = `CPU: ${resources.cpu / 1000} Cores, Mem: ${convertUnit(resources.mem, 'MB')}, Ephemeral storage: ${convertUnit(resources.storage, 'GB')}`;
  if (resources.gpu) {
    formattedResources += `, GPU: ${resources['gpu_model']} x ${resources.gpu}`;
  }
  return formattedResources
}

export function convertUnit(v, unit) {
  const units = ['KB', 'MB', 'GB', 'TB'];
  let index = units.indexOf(unit.toUpperCase());
  if (index === -1) {
    throw new Error('Invalid unit');
  }
  while (v >= 1024 && index < units.length - 1) {
    v /= 1024;
    index++;
  }
  return v + units[index];
}

export function getLinkByDeployment(deployment, forceEndpoint = false) {
  const strTags = _.get(deployment, 'Annotations.tags') || null;
  const tags = JSON.parse(strTags) || [];
  if (tags.indexOf('LLM') === -1 || forceEndpoint) {
    return deployment.Endpoint;
  }
  return window.location.protocol + '//' + window.location.host + `/dashboard/ai/${deployment.ProjectID}/llm-detail/${deployment.Shard}/${deployment.Suffix}`;
}

export function getModelByDeployment(deployment) {
  const args = _.get(deployment, 'ContainerArgs') || [];
  let idx = args.indexOf('--model')
  if (idx > -1) {
    return args[idx + 1];
  }
  return '';
}

export const getPodId = (podPhase) => {
  if (!podPhase) return '';
  let str = podPhase.split(';')[0]
  return str.split(': ')[0];
}

export const getStatus = (podPhase) => {
  if (!podPhase) return 'stopped';
  let str = podPhase.split(';')[0]
  return str.split(': ')[1];
}

export const getStatusSum = (podPhase, endpoint, withDetail = true) => {
  if (!podPhase && !endpoint) return <><StoppedIcon />{withDetail ? 'Stopped' : ''}</>;
  if (!podPhase) return <><Loader size={'xsmall'} color={'grey'} />{withDetail ? str : ''}</>
  let arr = podPhase.split(';');
  let len = arr.length;
  let sum = arr.reduce((sum, c) => {
    let cur = c.split(': ')[1] === 'Running' ? 1 : 0;
    return sum + cur;
  }, 0);
  const str = sum + ' / ' + len;
  if (sum > 0) {
    return <><ActiveIcon />{withDetail ? str : ''}</>;
  }
  return <><Loader size={'xsmall'} color={'grey'} />{withDetail ? str : ''}</>
}

export const getStatusString = (podPhase, endpoint) => {
  if (!podPhase && !endpoint) return '0.stopped';
  if (!podPhase) return '1.pending';
  let arr = podPhase.split(';');
  let len = arr.length;
  let sum = arr.reduce((sum, c) => {
    let cur = c.split(': ')[1] === 'Running' ? 1 : 0;
    return sum + cur;
  }, 0);
  const str = sum + ' / ' + len;
  if (sum > 0) {
    return '2.active';
  }
  return '1.pending';
}

export const checkActivePod = (deployment) => {
  if (!deployment) return false;
  if (deployment.EndpointStatus >= 500 || !deployment.PodPhase) {
    return false;
  }
  let arr = deployment.PodPhase.split(';');
  for (let pod of arr) {
    if (pod.split(': ')[1] === 'Running') {
      return true;
    }
  }
  return false;
}

export const checkActiveSSH = (node) => {
  if (!node.PodPhase) return false;
  let arr = node.PodPhase.split(';');
  for (let pod of arr) {
    if (pod.split(': ')[1] === 'Running') {
      return true;
    }
  }
  return false;
}

export const age = (time) => {
  let t = new Date(time)
  return moment(t.getTime()).fromNow();
}

export function generateRandomId(length) {
  let result = '';
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function generateRandomIdWithPrefix(prefix, length) {
  return prefix + generateRandomId(length - prefix.length);
}

export function formatDate(dateStr) {
  let date = new Date(dateStr);
  return moment(date.getTime()).format("MM/DD/YY hh:mma");
}

export function formatPrice(price) {
  const formattedPrice = parseFloat(price).toFixed(2);

  if (formattedPrice.endsWith('.00')) {
    return formattedPrice.slice(0, -3);
  }

  return formattedPrice;
}

export function updateAiProjectId(history, newId) {
  const currentPath = history.location.pathname;
  const pattern = /^\/dashboard\/ai\/([a-zA-Z0-9_]+)\/[^/]+$/;
  const match = currentPath.match(pattern);
  const id = match ? match[1] : null;
  const newPath = currentPath.replace(`/${id}/`, `/${newId}/`);
  history.replace(newPath);
}

export function formatBalance(balance) {
  return '$' + (balance / 100).toFixed(2);
}

export function findMinOption(target, list) {
  let left = 0;
  let right = list.length - 1;
  let res;

  while (left <= right) {
    let mid = Math.floor((left + right) / 2);
    if (list[mid] >= target) {
      res = list[mid];
      right = mid - 1;
    } else {
      left = mid + 1;
    }
  }
  return res;
}

export function getErrorMsg(msg, closeModel, curUser, orgId, vm, isBasic = false) {
  console.log('isBasic:', isBasic)
  let regex = /Shard is not configured (\d+)/;
  const onSendEmailWithVM = () => {
    window.open("mailto:support@thetaedgecloud.com" +
      "?subject=Increase%20Resources%20Request" +
      "&body=" +
      "%0A%0A%0A" +
      "User%20ID:%20" + curUser.id +
      "%0A" +
      "Organization%20ID:%20" + orgId +
      "%0A" +
      "VM%20Type:%20" + vm +
      "%0A%0A");
  }
  if (regex.test(msg.trim())) {
    return <>
      The requested resource is currently unavailable. Please try again later or <a onClick={onSendEmailWithVM}>contact support</a>.
    </>
  }
  switch (msg.trim()) {
    case "You've used up quota on this machine type":
      if (isBasic)
        return (curUser && curUser.role === UserRoleIds.VIEWER ? <>
          Your Theta EdgeCloud organization's quota plan doesn't support this feature. Please contact your organization's admin to request a quota increase.
        </> : <>
          Your Theta EdgeCloud organization's quota plan doesn't support this feature, please go to <Link to={mergeUrl(Urls.SETTINGS_QUOTA)} onClick={closeModel}>Quota</Link> to request a quota increase.
        </>)
      else
        return (curUser && curUser.role === UserRoleIds.VIEWER ? <>
          You've used up the quota for this machine type. Please contact your organization's admin to request a quota increase.
        </> : <>
          You've used up the quota for this machine type, please go to <Link to={mergeUrl(Urls.SETTINGS_QUOTA)} onClick={closeModel}>Quota</Link> to request a quota increase.
        </>)
    case "Deployment error: insufficient cluster resources for the requested deployment":
    case "Insufficient resources for the requested deployment":
      return <>
        There are no machines of this type currently available. Please try again later. Thank you for your patience as we look into increasing the machines we have for this type.
      </>
    default:
      return msg;
  }
}

export function getStreamErrorMsg(msg, curUser) {
  switch (msg.trim()) {
    case "Your Theta EdgeCloud org's quota plan doesn't support this feature.":
      return (curUser && curUser.role === UserRoleIds.VIEWER ? <>
        Your Theta EdgeCloud organization's quota plan doesn't support this feature. Please contact your organization's admin to request a quota increase.
      </> : <>
        Your Theta EdgeCloud organization's quota plan doesn't support this feature, please go to <Link to={mergeUrl(Urls.SETTINGS_QUOTA)}>Quota</Link> to request a quota increase.
      </>)
    default:
      return msg;
  }
}

export function checkDeploymentsReady(deployments) {
  if (deployments.length === 0) return false;
  for (let key in deployments) {
    if (deployments[key].PodPhase === undefined) {
      continue;
    }
    if (!checkActivePod(deployments[key])) {
      return false;
    }
  }
  return true;
}

export function checkIsBasicPlan(quotas, id) {
  if (!id) return true;
  for (let quota of quotas) {
    if (quota.name === 'Basic Plan') {
      return quota.id === id;
    }
  }
  return false;
}

export function getDatesBetween(startDate, endDate) {
  let dates = [];

  let currentDate = moment(startDate, 'YYYY-MM-DD');
  let stopDate = moment(endDate);

  while (currentDate <= stopDate) {
    dates.push(currentDate.format('YYYY-MM-DD'));
    currentDate = currentDate.clone().add(1, 'day');
  }

  return dates;
}