import React, { useState, useRef } from 'react';
import cx from 'classnames';
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../components/Modal";
import { Button } from "../components/Button";
import { useSelector, useDispatch } from "react-redux";
import { selectCurrentOrgId } from '../store/models/Organization';
import { CreditSelection } from '../components/CreditSelection';
import { AiTabs, AiTab } from '../components/AiTabs';
import Bill from '../store/models/Bill';
import { toast } from 'react-toastify';


export const AddCreditModal = ({ onRequestClose, couponOnly = false, coupon }) => {
  const dispatch = useDispatch()
  const orgId = useSelector(state => selectCurrentOrgId(state));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isOn, setIsOn] = useState(false);
  const [credit, setCredit] = useState(20);
  const couponRef = useRef();
  const creditRef = useRef();
  const [selectedTab, setSelectedTab] = useState((couponOnly || coupon) ? 'coupon' : 'purchase');
  const [isConfirming, setIsConfirming] = useState(false);


  const onSubmit = async () => {
    setError();
    if (!isConfirming) {
      if (selectedTab === 'coupon') {
        const coupon = couponRef.current.value;
        if (!coupon) {
          setError('Please enter a coupon.');
          return;
        }
      }
      if (selectedTab === 'purchase') {
        if (credit < 10) {
          setError('Minimum top-up: $10')
          return;
        }
      }
      setIsConfirming(true);
      return;
    }
    setLoading(true);
    try {
      if (selectedTab === 'purchase') {
        await dispatch(Bill.actions.purchaseCredit(orgId, credit * 100));
      } else if (selectedTab === 'coupon') {
        const coupon = couponRef.current.value;
        await dispatch(Bill.actions.applyCoupon(orgId, coupon));
      } else {
        setError('Something went wrong, please try again later.')
        setLoading(false);
      }
      await dispatch(Bill.actions.fetchBalance(orgId));
      await dispatch(Bill.actions.fetchBillHistory(orgId));
      toast.success('Success! Your credit has been successfully increased.');
      onRequestClose();
    } catch (e) {
      toast.error(`Error: ${e.message}`);
      setError(e.message);
    } finally {
      setLoading(false);
      setIsConfirming(false);
    }
    return;
  }

  const handleOnChange = (e) => {
    if (error) setError();
    let value = e.target.value;
    value = Math.floor(value * 100) / 100;
    setCredit(value);
  }

  const handleSetValue = (v) => {
    if(error) setError();
    setCredit(v);
  }

  return (
    <Modal className={cx("AddCreditModal")}
      onRequestClose={onRequestClose}>

      <ModalHeader>
        <ModalTitle>{couponOnly ? "Apply Coupon" : "Add Credit"}</ModalTitle>
      </ModalHeader>

      <ModalContent>
        <div className='AddCreditModal__content'>
          <div className='AddCreditModal__half-section'>
            {!couponOnly && <AiTabs>
              <AiTab isSelected={(selectedTab === 'purchase')}
                onClick={() => {
                  setSelectedTab('purchase')
                  if (isConfirming) setIsConfirming(false);
                  if (error) setError();
                }}>
                Purchase Credit
              </AiTab>
              <AiTab isSelected={(selectedTab === 'coupon')}
                onClick={() => {
                  setSelectedTab('coupon')
                  if (isConfirming) setIsConfirming(false);
                  if (error) setError();
                }}>
                Apply Coupon
              </AiTab>
            </AiTabs>}

            {selectedTab === 'purchase' && <div className='AddCreditModal__input-section'>
              <div className='AddCreditModal__input-selection-wrap'>
                <div className='AddCreditModal__input-credit-wrap'>
                  <div className="AddCreditModal__input-credit-label">
                    Purchase Credit Amount ($):
                  </div>
                  <input type='number'
                    placeholder='Enter purchase amount(min $10)'
                    className='AddCreditModal__input number'
                    ref={creditRef}
                    value={credit}
                    onChange={handleOnChange}
                    min="10" />
                </div>
                <CreditSelection value={credit} setValue={handleSetValue} />
              </div>
              {isConfirming &&
                <div className='AddCreditModal__input-overlay'>
                  Please confirm that you are adding <span>{`$${credit}`}</span> credit.
                </div>}
            </div>}
            {selectedTab === 'coupon' && <div className='AddCreditModal__input-section'>
              <div className='AddCreditModal__input-selection-wrap'>
                <input type='text' placeholder='Enter your coupon code' className='AddCreditModal__input' ref={couponRef} defaultValue={coupon} />
              </div>
              {isConfirming &&
                <div className='AddCreditModal__input-overlay'>
                  Please confirm that you are applying the coupon: <span>{`${couponRef.current.value}`}</span>
                </div>}
            </div>}
          </div>
        </div>
        {error && <div className={cx("AddCreditModal__error")}>Error: {error}</div>}
      </ModalContent>

      <ModalActions>
        <Button color={"green"} onClick={onSubmit} title={isConfirming ? `Confirm` : couponOnly ? "Apply Coupon" : "Add Credit"} loading={loading} />
        <Button color={"transparent"} onClick={isConfirming ? () => { setIsConfirming(false) } : onRequestClose} title={"Cancel"} />
      </ModalActions>

    </Modal>
  );
}

export default AddCreditModal;
