import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Stream, {selectStream} from "../../../store/models/Stream";
import {useParams} from "react-router";
import {useVideoJS} from "../../../hooks/useVideoJS";
import {BackButton, Button} from "../../../components/Button";
import {LiveDemoCardStep} from "../../marketing/LandingTVA";
import {useInterval} from "../../../hooks/useInterval";
import PageTitleBar from "../../../components/PageTitleBar";
import {Tab, Tabs} from "../../../components/Tabs";
import SyntaxHighlighter from 'react-syntax-highlighter';
import {a11yDark} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {Hosts, NetworkIdToName, PlayerUri} from "../../../constants";
import classNames from 'classnames';
import {IoCloseCircleOutline} from "react-icons/io5";
import {MdContentCopy} from "react-icons/md";
import {copyToClipboard, getStreamErrorMsg} from "../../../utils";
import {get, put} from '../../../utils/fetch';
import Select from 'react-select';
import {FaChartBar} from "react-icons/fa";
import {RiDeleteBin2Line} from "react-icons/ri";
import {NavLink} from "react-router-dom";
import {selectCurrentProject} from "../../../store/models/Project";
import { selectCurrentUser } from '../../../store/models/User';

const selectStyles = {
  container: styles => ({
    ...styles,
    flex: 1,
    maxWidth: 300,
  }),
  control: styles => ({
    ...styles,
    backgroundColor: '#191D29',
    borderColor: '#191D29',
  }),
  menu: (styles, state) => ({
    ...styles,
    borderBottom: '1px dotted #191D29',
    backgroundColor: '#191D29',
  }),
  menuList: () => ({
    display: "inline-block",
    overflowX: "hidden"
  }),
  option: (styles, {data, isDisabled, isFocused, isSelected}) => {
    return {
      ...styles,
      backgroundColor: '#191D29',
      borderColor: '#2C2F40',
      color: (isFocused ? 'white' : (isSelected ? '#18C99D' : '#8A8FB5')),
    };
  },
  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: '#8A8FB5',
  }),
  indicatorSeparator: (styles, state) => ({
    ...styles,
    color: '#8A8FB5',
    backgroundColor: '#8A8FB5',
    display: 'none'
  }),
  input: styles => ({...styles}),
  placeholder: styles => ({...styles, color: '#8A8FB5'}),
  singleValue: (styles, {data}) => ({...styles, color: 'white', fontWeight: '600'}),
  valueContainer: (styles) => ({
    ...styles,
    display: "inline-block",
    overflowX: "hidden"
  })
};

let intervalId;

function VideoPlayer({playbackUrl}) {
  const {Video, player, ready} = useVideoJS(
    {
      fluid: true,
      controls: true,
      sources: [{
        src: playbackUrl
      }],
      type: 'livestream'
    }
  );

  return (
    <Video muted loop autoPlay/>
  );
}

function StreamPage() {
  const dispatch = useDispatch();
  const {streamId} = useParams();
  const project = useSelector(state => selectCurrentProject(state));
  const stream = useSelector(state => selectStream(state, streamId));
  const curUser = useSelector(state => selectCurrentUser(state));
  const [selectedTab, setSelectedTab] = useState('embed-player');
  const [isHiddenKey, setIsHiddenKey] = useState(true);
  const [keyCopied, setKeyCopied] = useState(false);
  const [serverCopied, setServerCopied] = useState(false);
  const [ingestorList, setIngestorList] = useState([]);
  const [selectedIngestorId, setSelectedIngestorId] = useState();
  const [errMsg, setErrMsg] = useState('');
  const playbackUrl = stream?.use_studio_drm ? stream?.dash_playback_uri : stream?.playback_uri;

  useEffect(() => {
    if (!isHiddenKey) {
      setIsHiddenKey(true);
    }
    (async () => {
      if (streamId) {
        dispatch(Stream.actions.fetchStream(streamId));
      }
    })();
  }, [streamId]);

  useEffect(() => {
    if (!stream) return;
    if (!stream.stream_key && ingestorList.length === 0) {
      setTimeout(() => {
        fetchIngestorList();
        if (!intervalId) {
          intervalId = setInterval(() => {
            fetchIngestorList();
          }, 1000 * 60)
        }
      })
    }

  }, [stream, ingestorList])

  async function fetchIngestorList() {
    let result = await get({
      host: Hosts.API,
      url: `/ingestor/filter?max_distance=40000000`,
      headers: {
        'x-tva-sa-id': project.tva_id,
        'x-tva-sa-secret': project.tva_secret
      },
    });
    if (_.get(result, 'body.ingestors') && _.get(result, 'body.ingestors').length > 0) {
      setIngestorList(_.get(result, 'body.ingestors'));
    }
  }

  useEffect(() => {
    return () => clearInterval(intervalId)
  }, [])

  const handleSelect = () => {
    setErrMsg('');
    if (selectedIngestorId !== 'TVA Ingestor') {
      selectIngestor();
    } else {
      selectBackup();
    }

    async function selectIngestor() {
      try {
        let result = await put({
          host: Hosts.API,
          url: `/ingestor/${selectedIngestorId}/select`,
          headers: {
            'x-tva-sa-id': project.tva_id,
            'x-tva-sa-secret': project.tva_secret
          },
          body: {
            tva_stream: streamId
          },
        })
        if (_.get(result, 'status') === 'success') {
          dispatch(Stream.actions.fetchStream(streamId));
          setSelectedIngestorId('');
        }
      } catch (e) {
        let eMsg = e.message;
        if (eMsg.includes('already has a distribution claimed')) {
          setSelectedIngestorId('');
          setIngestorList([]);
          fetchIngestorList();
          eMsg = eMsg.split(':')[1].split('already has a distribution claimed')[0] + 'is not available. Please select another ingestor.';
        } else {
          eMsg = getStreamErrorMsg(eMsg, curUser);
        }
        setErrMsg(eMsg);
      }
    }

    async function selectBackup() {
      let result = await put({
        host: Hosts.API,
        url: `/stream/${streamId}/select`,
        headers: {
          'x-tva-sa-id': project.tva_id,
          'x-tva-sa-secret': project.tva_secret
        },
      })
      if (_.get(result, 'status') === 'success') {
        dispatch(Stream.actions.fetchStream(streamId));
      }
    }
  }

  const handleUnSelect = () => {
    setErrMsg('');
    if (stream.ingestor_id) {
      unselectIngestor();
    } else {
      unselectBackup();
    }

    async function unselectIngestor() {
      let result = await put({
        host: Hosts.API,
        url: `/ingestor/${stream.ingestor_id}/unselect`,
        headers: {
          'x-tva-sa-id': project.tva_id,
          'x-tva-sa-secret': project.tva_secret
        },
      })
      if (_.get(result, 'body.status') === 'unselect succeeded') {
        dispatch(Stream.actions.fetchStream(streamId));
      }
    }

    async function unselectBackup() {
      let result = await put({
        host: Hosts.API,
        url: `/stream/${streamId}/turn_off`,
        headers: {
          'x-tva-sa-id': project.tva_id,
          'x-tva-sa-secret': project.tva_secret
        },
      })
      if (_.get(result, 'status') === 'success') {
        dispatch(Stream.actions.fetchStream(streamId));
      }
    }
  }

  useInterval(() => {
    if (streamId) {
      dispatch(Stream.actions.fetchStream(streamId));
    }
  }, 15 * 1000);

  const copyKey = () => {
    copyToClipboard(stream.stream_key || stream.backup_stream_key);
    setKeyCopied(true);
    setTimeout(() => {
      setKeyCopied(false);
    }, 1000)
  }

  const copyServer = () => {
    copyToClipboard(stream.stream_server || stream.backup_stream_server);
    setServerCopied(true);
    setTimeout(() => {
      setServerCopied(false);
    }, 1000)
  }

  const onSelectChange = (option) => {
    setErrMsg('');
    setSelectedIngestorId(option.value);
  };
  const onClickDelete = (e) => {
    if (stream && (stream.stream_key || stream.status !== 'off')) e.preventDefault()
  }

  return (
    <div className={'VideoPage'}>
      <div className={'VideoPage__content'}>
        <PageTitleBar title={stream?.name || 'livestreams'}
                      leftContent={<BackButton title={'Livestreams'}
                                               href={`/dashboard/video/livestreams`}
                      />}
                      rightContent={<>
                        {/* <Button href={`/dashboard/video/${dapp.id}/livestreams/${streamId}/delete`}
              title={'Delete'} size={'small'} color={'grey'} disabled={stream && (stream.stream_key || stream.status !== 'off')}
              className={'Button--has-icon'}
              icon={<RiDeleteBin2Line />}
            /> */}
                        <NavLink
                          className={classNames('NavbarLink delete', {'disabled': stream && (stream.stream_key || stream.status !== 'off')})}
                          onClick={onClickDelete}
                          to={`/dashboard/video/livestreams/${streamId}/delete`}
                          exact
                        >
                          <RiDeleteBin2Line/>
                        </NavLink>
                        <Button href={`/dashboard/video/metrics?streamId=${streamId}`}
                                title={'Metrics'} size={'medium'} color={'transparent'}
                                className={'Button--has-icon Button--metric'}
                                icon={<FaChartBar/>}
                        />

                        {/* <NavLink className={classNames('NavbarLink delete', { 'disabled': stream && (stream.stream_key || stream.status !== 'off') })}
              onClick={onClickDelete}
              to={`/dashboard/video/${dapp.id}/livestreams/${streamId}/delete`}
              exact
            >
              <RiDeleteBin2Line />
            </NavLink> */}
                        {/*
            <div className='PageTitleBar__right--icon'>
              <NavLink className={'NavbarLink metric'}
                activeClassName={'NavbarLink--active'}
                to={`/dashboard/video/metrics?streamId=${streamId}`}
                exact
              >
                <FaChartBar />
              </NavLink>
            </div> */}
                      </>}
        />
        {
          stream && <>
            <div className='VideoPage__info'>
              <div className='VideoPage__info--row'>
                <div className={'VideoPage__info--header'}>Stream Id:</div>
                <div className={'VideoPage__info--content'}>{streamId}</div>
              </div>
              <div className='VideoPage__info--row'>
                <div className={'VideoPage__info--header'}>Stream Status:</div>
                <div className={classNames('VideoPage__info--content', {'online': stream.status === 'on'})}>
                  {stream.status === 'on' ? 'Online' : 'Offline'}
                </div>
              </div>
              {!stream.stream_key && stream.status === 'off' && <div className='VideoPage__info--row'>
                <div className={'VideoPage__info--header'}>Select Ingestor:</div>
                <div className={'VideoPage__info--content'}>
                  <div className='ingestor-selector'>
                    <Select options={ingestorList.map(ingestor => ({
                      label: `Edge Ingestor: ${ingestor.id}`,
                      value: ingestor.id
                    }))}
                            value={selectedIngestorId ? {
                              label: `${selectedIngestorId !== 'TVA Ingestor' ? 'Edge Ingestor: ' : ''}${selectedIngestorId}`,
                              value: selectedIngestorId
                            } : ''}
                            styles={selectStyles}
                            placeholder='Select Ingestor'
                            onChange={onSelectChange}
                    />
                    {selectedIngestorId && <Button title={'Confirm'}
                                                   size={'small'}
                                                   onClick={handleSelect}
                    />}
                  </div>
                </div>
              </div>}
              {(stream.stream_key || stream.status !== 'off') && <>
                <div className='VideoPage__info--row'>
                  <div className={'VideoPage__info--header'}>Stream Server:</div>
                  <div className={'VideoPage__info--content'}>
                    {stream.stream_server || stream.backup_stream_server}
                    {serverCopied ? <div className='VideoPage__info--success'>Copied!</div> :
                      <MdContentCopy className='VideoPage__button--copy' onClick={copyServer}
                                     title="Copy Stream Server"/>}
                  </div>
                </div>
                <div className='VideoPage__info--row'>
                  <div className={'VideoPage__info--header'}>Stream Key:</div>
                  <>
                    {isHiddenKey ? <div className={'VideoPage__info--content'}>
                      <div className='VideoPage__button' onClick={() => setIsHiddenKey(false)}>Show Stream Key</div>
                      {keyCopied ? <div className='VideoPage__info--success'>Copied!</div> :
                        <MdContentCopy className='VideoPage__button--copy' onClick={copyKey} title="Copy Stream Key"/>}
                    </div> : <div className={'VideoPage__info--content'}>
                      <>
                        <div
                          title={stream.stream_key}>{_.truncate(stream.stream_key || stream.backup_stream_key, {length: 22})}</div>
                        <IoCloseCircleOutline onClick={() => setIsHiddenKey(true)} className='VideoPage__button--close'/>
                      </>
                      {keyCopied ? <div className='VideoPage__info--success'>Copied!</div> :
                        <MdContentCopy className='VideoPage__button--copy' onClick={copyKey}/>}
                    </div>}

                  </>
                  {/* <div className={'VideoPage__info--content'} title={stream.stream_key}>
                  {_.truncate(stream.stream_key, { length: 22 })}
                </div> */}
                </div>
              </>}
              {(stream.ingestor_id || stream.status !== 'off') && <div className='VideoPage__info--row'>
                <div className={'VideoPage__info--header'}>Ingestor Id:</div>
                <div className={'VideoPage__info--content'}>
                  <div
                    title={stream.ingestor_id}>{stream.ingestor_id ? 'Edge Ingestor: ' + _.truncate(stream.ingestor_id, {length: 20}) : 'TVA Ingestor'}</div>
                  {!playbackUrl &&
                    <Button title={'Unselect'} onClick={handleUnSelect} color={'grey'} size={'small'}/>}
                </div>
              </div>
              }
              {(stream.ingestor_id && stream.status === 'off') &&
                <div className='VideoPage__info--warning'>Note: The selected ingestor will be released if not used in ~5
                  minutes</div>
              }
              {errMsg && <div className='VideoPage__info--error'>Error: {errMsg}</div>}
            </div>
          </>
        }
        {
          stream && stream.state === "error" &&
          <div className="URLCard URLCard--red">
            <div>{stream.error}</div>
          </div>
        }
        {
          stream && _.isNil(playbackUrl) && stream.state !== "error" &&
          <div className='stream-offline' style={{backgroundImage: "url('/images/offline-static.gif')"}}></div>
        }
        {
          stream && !_.isNil(playbackUrl) &&
          <div className="URLCard URLCard--green">
            {(_.isNil(stream.use_drm) || !stream.use_drm) ? playbackUrl : `${PlayerUri}/video/${streamId}`}
          </div>
        }
        {
          stream && !_.isNil(playbackUrl) &&
          <div style={{width: '100%'}}>
            {
              (_.isNil(stream.use_drm) || !stream.use_drm) &&
              <div className={'VideoPage__video-wrapper'}>
                <VideoPlayer playbackUrl={playbackUrl}/>
                {/* <iframe src={`${PlayerUri}/stream/${streamId}`} 
                        border="0" 
                        width="100%" 
                        height="100%"
                        allowfullscreen/> */}
              </div>
            }
            {
              !_.isNil(stream.use_drm) && stream.use_drm &&
              <div className={'VideoPage__drm-description'}>
                <div>This stream is only available to NFT owners in collection:</div>
                {stream.drm_rules && stream.drm_rules.map(rule => {
                  return <div>{rule.nft_collection}({NetworkIdToName[rule.chain_id]})</div>
                })}
              </div>
            }

            <LiveDemoCardStep number={null}
                              title={'Optimize with Theta p2p'}
                              description={'Save up to 60% by integrating the Theta P2P in a few lines of code.'}
            >
              <Tabs>
                <Tab isSelected={(selectedTab === 'embed-player')}
                     onClick={() => {
                       setSelectedTab('embed-player')
                     }}>
                  Embed Player
                </Tab>
                <Tab isSelected={(selectedTab === 'video.js')}
                     onClick={() => {
                       setSelectedTab('video.js')
                     }}>
                  Video.js
                </Tab>
              </Tabs>

              {
                (selectedTab === 'embed-player') &&
                <React.Fragment>
                  <SyntaxHighlighter language="javascript" style={a11yDark} wrapLines={true} wrapLongLines={true}
                                     customStyle={{
                                       marginBottom: 30,
                                       borderRadius: 8,
                                       backgroundColor: '#191D29',
                                       padding: 20
                                     }}>
                    {`
<iframe src=\"${playbackUrl}\" 
        border=\"0\" 
        width=\"100%\" 
        height=\"100%\"
        allowfullscreen/>
`}
                  </SyntaxHighlighter>
                </React.Fragment>
              }
              {
                (selectedTab === 'video.js') &&
                <React.Fragment>
                  <div className={'VideoPage__docs-instructions'}>Requirement: Follow the stream.js setup instructions
                    first
                  </div>
                  <Button href={'https://docs.thetatoken.org/docs/theta-p2p-javascript-sdk#include-scripts'}
                          target='_blank'
                          color={'grey'}
                          size={'small'}
                          title={'View Setup Docs'}
                          style={{marginTop: 20}}
                  >
                  </Button>
                  <SyntaxHighlighter language="javascript" style={a11yDark} wrapLines={true} wrapLongLines={true}
                                     customStyle={{
                                       marginBottom: 30,
                                       borderRadius: 8,
                                       backgroundColor: '#191D29',
                                       padding: 20
                                     }}>
                    {`
const optionalHlsOpts = null;
const player = window.player = videojs('player-container', {
  techOrder: ["theta_hlsjs", "html5"],
  sources: [{
    src: "${playbackUrl}",
    type: "application/vnd.apple.mpegurl",
    label: "auto"
  }],
  theta_hlsjs: {
    streamId: "${stream?.id}",
    userId: "YOUR_AUTHED_USER_ID",
    walletUrl: "wss://api-wallet-service.thetatoken.org/theta/ws",
    onWalletAccessToken: null,
    hlsOpts: null
  }
});
                    `}
                  </SyntaxHighlighter>
                </React.Fragment>
              }
            </LiveDemoCardStep>
          </div>
        }
      </div>
    </div>
  );
}


export default StreamPage;
