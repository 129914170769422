import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { CopyIcon, DeleteIcon, InfoIcon, RotateIcon, UploadIconIcon } from "../Svg";
import {
    ModalTypes,
    RAGChatbotMaxTemp, RAGChatbotMaxTokens, RAGChatbotMinTemp, RAGChatbotMinTokens,
    RAGChatbotTempStep, RAGChatbotTokenStep
} from "../../constants";
import Ai from '../../store/models/Ai';
import { Button } from '../Button';
import { toast } from 'react-toastify';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import Slider from '../Slider';
import { ChromePicker } from 'react-color';
import { SelectInput } from '../form/SelectInput';
import UIState from '../../store/UIState';
import { GrAdd } from "react-icons/gr";
import Tooltip from '../Tooltip';
import { ChatbotColors } from '../../constants/chatbot-colors';
import { Upload } from '../../store/models/Upload';
import { ImageInput } from '../form/ImageInput';
import Loader from '../Loader';
import { Checkbox } from '../form/Checkbox';
import cx from 'classnames';


const getReadableColorName = (cssVarName) => {
    return cssVarName
        .replace('--color-', '')
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

const SettingsSection = ({ title, children }) => (
    <div className='RagChatbotDetailPage__section'>
        <h3 className='RagChatbotDetailPage__section-title'>{title}</h3>
        <div className='RagChatbotDetailPage__section-content'>
            {children}
        </div>
    </div>
);

const TAB_CONFIG = {
  llm: {
    label: 'LLM Config',
    description: 'Configure your Language Model settings'
  },
  behavior: {
    label: 'Customization',
    description: 'Configure how your chatbot interacts'
  },
  visual: {
    label: 'Appearance',
    description: 'Customize the appearance of your chatbot'
  },
  feedbacks: {
    label: 'User Feedback',
    description: 'Configure user feedback settings'
  },
  privacy: {
    label: 'Privacy',
    description: 'Configure privacy and analytics settings'
  }
};

export const RagSettingsTab = (props) => {
    const { chatbot, projectId, accessToken, setAccessToken } = props;
    const dispatch = useDispatch();
    const endpointOptions = chatbot.llm_endpoints.filter(c => c.id.startsWith('llm_')).map(c => ({ label: c.name, value: c.id }));
    const customEndpoints = chatbot.llm_endpoints.filter(c => c.id.startsWith('c_llm_'))
    const customEndpointOptions = customEndpoints.map(c => ({ label: c.name || c.id, value: c.id, type: 'custom' })).concat({ label: 'Add your own LLM endpoint', value: 'add-custom', type: 'add-custom' });
    let groupedOptions = [{
        label: 'Standard',
        options: endpointOptions
    }, {
        label: 'Custom',
        options: customEndpointOptions
    }]
    // const [endpointOptions, setEndpointOptions] = useState(chatbot.filter(c => c.id.startsWith('llm_')).map(c => ({ label: c.name, value: c.id })));
    const [value, setValue] = useState(() => {
        let id = _.get(chatbot, 'ui.llm_endpoint_id');
        let option = endpointOptions.find(e => e.value === id);
        if (!option) {
            option = customEndpointOptions.find(e => e.value === id);
        }
        return option;
    });

    const [maxTokens, setMaxTokens] = useState(chatbot.max_tokens);
    const [temperature, setTemperature] = useState(chatbot.temperature);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [questions, setQuestions] = useState(_.get(chatbot, 'ui.preset_questions') || []);
    const [warmupQuestions, setWarmupQuestions] = useState(_.get(chatbot, 'warm_up_messages') || []);
    const [showColorSettings, setShowColorSettings] = useState(false);
    const [colors, setColors] = useState(() => {
        const savedColors = _.get(chatbot, 'ui.css_colors', {});
        return ChatbotColors.reduce((acc, { key, default: defaultValue }) => {
            acc[key] = savedColors[key] || defaultValue;
            return acc;
        }, {});
    });

    const [backgroundImageUrl, setBackgroundImageUrl] = useState(_.get(chatbot, 'ui.background_image'));

    const retrieverOptions = [
        { value: 'default', label: 'Default' },
        { value: 'llm', label: 'LLM' },
        { value: 'rerank', label: 'Rerank' },
        { value: 'none', label: 'None' },
    ];
    const [retriever, setRetriever] = useState(_.find(retrieverOptions, { value: chatbot?.settings?.['retriever.type'] }) ?? retrieverOptions[0]);

    const engineOptions = [
        { value: 'default', label: 'None' },
        { value: 'web_search', label: 'Web Search' },
        { value: 'text_to_sql', label: 'SQL Analysis' },
    ];

    const [selectedTools, setSelectedTools] = useState(() => {
        const agentTools = chatbot?.settings?.['agent.tools'] || {};
        // Check if any non-default tools are enabled
        const hasActiveTools = Object.entries(agentTools).some(([key, value]) => value === true);

        if (hasActiveTools) {
            // If there are active tools, only return those tools
            return engineOptions.filter(option =>
                option.value !== 'default' && agentTools[option.value] === true
            );
        } else {
            // If no active tools, return default
            return [engineOptions[0]];
        }

        // const agentTools = chatbot?.settings?.['agent.tools'] || {};
        // return engineOptions.filter(option => 
        // option.value === 'default' || agentTools[option.value] === true
        // );
    });

    const [avatarUrl, setAvatarUrl] = useState(_.get(chatbot, 'ui.icon'));
    const [headerBannerUrl, setHeaderBannerUrl] = useState(_.get(chatbot, 'ui.header_banner'));
    const [widgetIconOpen, setWidgetIconOpen] = useState(_.get(chatbot, 'ui.widget_icon_open'));
    const [widgetIconClose, setWidgetIconClose] = useState(_.get(chatbot, 'ui.widget_icon_close'));
    const [uploadingImage, setUploadingImage] = useState(null);
    const [disableAnalytics, setDisableAnalytics] = useState(_.get(chatbot, 'ui.disable_tracking', false));
    const [activeTab, setActiveTab] = useState('llm');
    const [enableFeedback, setEnableFeedback] = useState(_.get(chatbot, 'ui.enable_answer_feedback', false));
    const [feedbackSamplingRate, setFeedbackSamplingRate] = useState(_.get(chatbot, 'ui.feedback_sampling_rate', 100));

    const [systemMessage, setSystemMessage] = useState(_.get(chatbot, 'system_prompt', ''));
    const [defaultDialog, setDefaultDialog] = useState(_.get(chatbot, 'ui.default_dialog', ''));
    const [disclaimerDialog, setDisclaimerDialog] = useState(_.get(chatbot, 'ui.disclaimer_dialog', ''));
    const [disclaimerButtonLabel, setDisclaimerButtonLabel] = useState(_.get(chatbot, 'ui.disclaimer_button_label', 'I Agree'));
    const [headerSlogan, setHeaderSlogan] = useState(_.get(chatbot, 'ui.header_slogan', ''));

    const onEndpointChange = (v) => {
        if (v.value === 'add-custom') {
            dispatch(UIState.actions.showModal(ModalTypes.UPDATE_LLM_ENDPOINT, { type: 'create', projectId, chatbotId: chatbot.id }))
            return;
        }
        setValue(v);
    }

    const onSetMaxTokens = (event) => {
        const value = event.target.value;
        if (_.isEmpty(value)) {
            setMaxTokens(0)
        } else if (value < RAGChatbotMinTokens) {
            setMaxTokens(RAGChatbotMinTokens)
        } else if (value > RAGChatbotMaxTokens) {
            setMaxTokens(RAGChatbotMaxTokens)
        } else {
            setMaxTokens(value)
        }
    }

    const onSetTemperature = (event) => {
        const value = event.target.value;
        if (value < RAGChatbotMinTemp) {
            setTemperature(RAGChatbotMinTemp)
        } else if (value > RAGChatbotMaxTemp) {
            setTemperature(RAGChatbotMaxTemp)
        } else {
            setTemperature(value)
        }
    }

    const handleThemeChange = (newTheme) => {
        setColors(prevColors => {
            return ChatbotColors.reduce((acc, { key, default: defaultValue, light, vgk }) => {
                acc[key] = newTheme === 'light' ? light : (newTheme === 'vgk' ? vgk : defaultValue);
                return acc;
            }, {});
        });
    };

    const handleColorChange = (colorKey, color) => {
        setColors(prevColors => ({
            ...prevColors,
            [colorKey]: color
        }));
    };

    const handleUpdate = async () => {
        if (!value) {
            setError("LLM Endpoint can't be empty.");
            toast.error("LLM Endpoint can't be empty.");
            return;
        }
        let endpoint = value.value;
        setLoading(true);
        setError(null);
        try {
            let obj = {
                name: chatbot.name,
                project_id: projectId,
                max_tokens: maxTokens,
                temperature: temperature,
                system_prompt: systemMessage,
                warm_up_messages: warmupQuestions,
                llm_endpoint_id: endpoint,
                settings: {
                    'retriever.type': retriever.value,
                    'agent.tools': engineOptions.reduce((acc, tool) => {
                        if (tool.value === 'default') {
                            return acc;
                        }
                        if (selectedTools.find(t => t.value === 'default')) {
                            acc[tool.value] = false;
                        } else {
                            acc[tool.value] = selectedTools.some(t => t.value === tool.value);
                        }
                        return acc;
                    }, {})
                },
                ui: {
                    default_dialog: defaultDialog,
                    disclaimer_dialog: disclaimerDialog,
                    disclaimer_button_label: disclaimerButtonLabel,
                    header_slogan: headerSlogan,
                    icon: avatarUrl,
                    header_banner: headerBannerUrl,
                    preset_questions: questions,
                    css_colors: colors,
                    widget_icon_open: widgetIconOpen,
                    widget_icon_close: widgetIconClose,
                    disable_tracking: disableAnalytics,
                    enable_answer_feedback: enableFeedback,
                    feedback_sampling_rate: feedbackSamplingRate,
                    background_image: backgroundImageUrl,
                }
            };
            await dispatch(Ai.actions.updateRagChatbot(chatbot.id, obj));
            await dispatch(Ai.actions.fetchRagChatbot(projectId, chatbot.id));
            setLoading(false);
            toast.success(`Chatbot ${chatbot.name} has been updated!`)
        } catch (e) {
            console.log('error:', e.message)
            setLoading(false);
            toast.error(e.message);
            setError(e.message);
        }
    }

    const handleCopy = () => {
        navigator.clipboard.writeText(accessToken)
            .then(() => {
                toast.success('Access Token Copied!')
            })
            .catch((err) => {
                console.error('Failed to copy:', err);
            });
    }

    const handleRotate = () => {
        const onConfirm = async () => {
            try {
                let res = await dispatch(Ai.actions.rotateRagAccessToken(projectId, chatbot.id));
                setAccessToken(res);
                toast.success(`Access token has been updated.`)
            } catch (e) {
                toast.error(`Oops, something went wrong. Error: ${e.message}`)
            }
        }
        dispatch(UIState.actions.showModal(ModalTypes.CONFIRM, {
            title: `Rotate access token`,
            message: `Do you want to update the access token?`,
            confirmLabel: `Rotate`,
            cancelLabel: `Close`,
            onConfirm
        }))
    }

    const onImageUpload = async (file, type) => {
        try {
            setUploadingImage(type);
            const fileName = `${type}-${new Date().getTime()}${file.name.substring(file.name.lastIndexOf('.'))}`;
            const url = await dispatch(Upload.actions.uploadFile(projectId, fileName, file));

            switch (type) {
                case 'avatar':
                    setAvatarUrl(url);
                    break;
                case 'widget-open':
                    setWidgetIconOpen(url);
                    break;
                case 'widget-close':
                    setWidgetIconClose(url);
                    break;
                case 'banner':
                    setHeaderBannerUrl(url);
                    break;
                case 'background':
                    setBackgroundImageUrl(url);
                    break;
            }
        } catch (error) {
            console.error(`Failed to upload ${type}:`, error);
            toast.error(`Failed to upload ${type} image`);
        }
        setUploadingImage(null);
    }


    const onSelectedToolsChange = (value) => {
        // If no options are selected, select Default
        if (value.length === 0) {
            setSelectedTools([engineOptions[0]]);  // engineOptions[0] is Default
            return;
        }

        // If Default is being selected
        if (value.find(v => v.value === 'default') && !selectedTools.find(t => t.value === 'default')) {
            setSelectedTools([engineOptions[0]]);
            return;
        }

        // If another option is being selected while Default is selected
        if (selectedTools.find(t => t.value === 'default') && value.length > 1) {
            setSelectedTools(value.filter(v => v.value !== 'default'));
            return;
        }

        setSelectedTools(value);
    };

    return <div className='RagChatbotDetailPage row'>
        <div className='RagChatbotDetailPage__tabs'>
            {Object.entries(TAB_CONFIG).map(([key, { label }]) => (
                <div
                    key={key}
                    className={cx("RagChatbotDetailPage__tab", { active: activeTab === key })}
                    onClick={() => setActiveTab(key)}
                >
                    {label}
                </div>
            ))}
        </div>

        <div className='RagChatbotDetailPage__tab-content'>
            {error && <div className='RagChatbotDetailPage__error'>{error}</div>}
            {activeTab === 'llm' && (
                <>
                    <div className='RagChatbotDetailPage__row'>
                        <div className='RagChatbotDetailPage__input-wrap'>
                            <div className='RagChatbotDetailPage__input-label'>LLM Endpoint</div>
                            <CreatableSelect
                                options={groupedOptions}
                                className={"RagChatbotDetailPage__selector"}
                                placeholder={"LLM Endpoint, e.g. thetalabsorg/ubuntu-sshd:latest"}
                                styles={selectStyles}
                                onChange={onEndpointChange}
                                value={value}
                                isDisabled={!groupedOptions}
                                components={{ Option: (props) => <CustomOption {...props} customEndpoints={customEndpoints} selectedId={_.get(chatbot, 'ui.llm_endpoint_id')} /> }}
                            />
                        </div>
                    </div>
                    <div className='RagChatbotDetailPage__row'>
                        <div className='RagChatbotDetailPage__input-wrap'>
                            <div className='RagChatbotDetailPage__input-label'>Access token</div>
                            <div className='RagChatbotDetailPage__input-div token'>
                                <div className='RagChatbotDetailPage__input-div--info'>{accessToken}</div>
                                <div className='RagChatbotDetailPage__input-div--buttons'>
                                    <div className='RagChatbotDetailPage__input-div--icon' onClick={handleCopy}>
                                        <CopyIcon />
                                    </div>
                                    <div className='RagChatbotDetailPage__input-div--icon' onClick={handleRotate}>
                                        <RotateIcon />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='RagChatbotDetailPage__row large-gap'>
                        <div className='RagChatbotDetailPage__input-wrap slider'>
                            <div className='RagChatbotDetailPage__input-label'>Max tokens</div>
                            <Slider className='RagChatbotDetailPage__slider-wrap' step={RAGChatbotTokenStep} id="maxTokensId"
                                max={RAGChatbotMaxTokens} min={RAGChatbotMinTokens} value={maxTokens} onChange={onSetMaxTokens} />
                        </div>
                        <div className='RagChatbotDetailPage__input-wrap slider'>
                            <div className='RagChatbotDetailPage__input-label'>Temperature</div>
                            <Slider className='RagChatbotDetailPage__slider-wrap' step={RAGChatbotTempStep} id="temperatureId"
                                max={RAGChatbotMaxTemp} min={RAGChatbotMinTemp} value={temperature} onChange={onSetTemperature} />
                        </div>
                    </div>
                    <div className='RagChatbotDetailPage__row large-gap'>
                        <div className='RagChatbotDetailPage__input-wrap'>
                            <div className='RagChatbotDetailPage__input-label'>Retriever</div>
                            <SelectInput
                                options={retrieverOptions}
                                value={retriever}
                                onChange={(value) => setRetriever(value)}
                                placeholder="Select Retriever"
                                styles={selectStyles}
                                components={{ Option: (props) => <CustomOption {...props} selectedId={chatbot?.settings?.['retriever.type']} /> }}
                            />
                        </div>
                        <div className='RagChatbotDetailPage__input-wrap slider'>
                            <div className='RagChatbotDetailPage__input-label'>Engine Tools</div>
                            <SelectInput
                                options={engineOptions}
                                value={selectedTools}
                                onChange={onSelectedToolsChange}
                                placeholder="Select Engine Tools"
                                styles={selectStyles}
                                isMulti={true}
                                components={{ Option: (props) => <CustomOption {...props} /> }}
                            />
                        </div>
                    </div>
                    <div className='RagChatbotDetailPage__row'>
                        <div className='RagChatbotDetailPage__input-wrap'>
                            <div className='RagChatbotDetailPage__input-label'>System message
                                <Tooltip tooltip={"Give instructions to the chatbot on how to respond to user messages, give a personality to the chatbot. Not visible to users."}>
                                    <InfoIcon />
                                </Tooltip>
                            </div>
                            <textarea 
                                className='RagChatbotDetailPage__textarea RagChatbotDetailPage__textarea--large'
                                value={systemMessage}
                                onChange={(e) => setSystemMessage(e.target.value)}
                            />
                        </div>
                    </div>
                </>
            )}

            {activeTab === 'behavior' && (
                <>
                    <div className='RagChatbotDetailPage__row'>
                        <div className='RagChatbotDetailPage__input-wrap'>
                            <div className='RagChatbotDetailPage__input-label'>Default dialog
                                <Tooltip tooltip={"Welcome message displayed when the chatbot is opened."}>
                                    <InfoIcon />
                                </Tooltip>
                            </div>
                            <input
                                className='RagChatbotDetailPage__input'
                                value={defaultDialog}
                                onChange={(e) => setDefaultDialog(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='RagChatbotDetailPage__row'>
                        <div className='RagChatbotDetailPage__input-wrap'>
                            <div className='RagChatbotDetailPage__input-label'>Disclaimer dialog
                                <Tooltip tooltip={"Disclaimer message that replaces the default dialog. Users will need to agree to continue. (markdown supported)"}>
                                    <InfoIcon />
                                </Tooltip>
                            </div>
                            <input
                                className='RagChatbotDetailPage__input'
                                value={disclaimerDialog}
                                onChange={(e) => setDisclaimerDialog(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='RagChatbotDetailPage__row'>
                        <div className='RagChatbotDetailPage__input-wrap'>
                            <div className='RagChatbotDetailPage__input-label'>Disclaimer button label
                                <Tooltip tooltip={"Text displayed on the button when a disclaimer is shown."}>
                                    <InfoIcon />
                                </Tooltip>
                            </div>
                            <input 
                                className='RagChatbotDetailPage__input'
                                value={disclaimerButtonLabel}
                                onChange={(e) => setDisclaimerButtonLabel(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='RagChatbotDetailPage__row large-gap'>
                        <div className='RagChatbotDetailPage__input-wrap'>
                            <div className='RagChatbotDetailPage__input-label'>Preset questions
                                <Tooltip tooltip={"Questions will be displayed at the bottom of the chatbot. Answers are optional, if not provided, the chatbot will generate an answer."}>
                                    <InfoIcon />
                                </Tooltip>
                            </div>
                            <div className='RagChatbotDetailPage__preset-questions' >
                                <div className='RagChatbotDetailPage__preset-questions--wrap'>
                                    <QuestionsSection questions={questions} setQuestions={setQuestions} />
                                    <div className='RagChatbotDetailPage__preset-questions--icon' onClick={() => setQuestions(q => q.concat({ question: '', answer: '' }))}>
                                        <UploadIconIcon /> New
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='RagChatbotDetailPage__row large-gap'>
                        <div className='RagChatbotDetailPage__input-wrap'>
                            <div className='RagChatbotDetailPage__input-label'>Warmup messages
                                <Tooltip tooltip={"Messages that will be used to warm up the chatbot. They will be added to the chatbot knowledge and won't be displayed to users."}>
                                    <InfoIcon />
                                </Tooltip>
                            </div>
                            <div className='RagChatbotDetailPage__preset-questions' >
                                <div className='RagChatbotDetailPage__preset-questions--wrap'>
                                    <WarmupMessagesSection questions={warmupQuestions} setQuestions={setWarmupQuestions} />
                                    <div className='RagChatbotDetailPage__preset-questions--icon' onClick={() => setWarmupQuestions(q => q.concat({ role: 'user', content: '' }))}>
                                        <UploadIconIcon /> New
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}

            {activeTab === 'visual' && (
                <>
                    <div className='RagChatbotDetailPage__row'>
                        <div className='RagChatbotDetailPage__input-wrap'>
                            <div className='RagChatbotDetailPage__input-label'>Chatbot avatar
                                <Tooltip tooltip={"Avatar image displayed in front of the chatbot."}>
                                    <InfoIcon />
                                </Tooltip>
                            </div>
                            {uploadingImage === 'avatar' ? <Loader />
                                : <ImageInput
                                    name="avatar"
                                    value={avatarUrl}
                                    onChange={(file) => onImageUpload(file, 'avatar')}
                                    onRemove={() => {
                                        setAvatarUrl('');
                                    }}
                                    instructions="Drag & drop or choose an image for the chatbot avatar"
                                    formats={['image/jpeg', 'image/png', 'image/gif']}
                                />}
                        </div>
                    </div>

                    <div className='RagChatbotDetailPage__row'>
                        <div className='RagChatbotDetailPage__input-wrap'>
                            <div className='RagChatbotDetailPage__input-label'>Header slogan
                                <Tooltip tooltip={"Slogan text displayed at the top of the chatbot full page."}>
                                    <InfoIcon />
                                </Tooltip>
                            </div>
                            <input
                                className='RagChatbotDetailPage__input'
                                value={headerSlogan}
                                onChange={(e) => setHeaderSlogan(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='RagChatbotDetailPage__row'>
                        <div className='RagChatbotDetailPage__input-wrap'>
                            <div className='RagChatbotDetailPage__input-label'>Header Banner
                                <Tooltip tooltip={"Banner image displayed at the top of the chatbot."}>
                                    <InfoIcon />
                                </Tooltip>
                            </div>
                            {uploadingImage === 'banner' ? <Loader />
                                : <ImageInput
                                    name="header_banner"
                                    value={headerBannerUrl}
                                    onChange={(file) => onImageUpload(file, 'banner')}
                                    onRemove={() => {
                                        setHeaderBannerUrl('');
                                    }}
                                    instructions="Drag & drop or choose an image for the header banner"
                                    formats={['image/jpeg', 'image/png', 'image/gif']}
                                />}
                        </div>
                    </div>

                    <div className='RagChatbotDetailPage__row'>
                        <div className='RagChatbotDetailPage__input-wrap'>
                            <div className='RagChatbotDetailPage__input-label'>Background Image
                                <Tooltip tooltip={"Background image displayed behind the chat messages. Will be tiled if smaller than the chat area."}>
                                    <InfoIcon />
                                </Tooltip>
                            </div>
                            {uploadingImage === 'background' ? <Loader />
                                : <ImageInput
                                    name="background_image"
                                    value={backgroundImageUrl}
                                    onChange={(file) => onImageUpload(file, 'background')}
                                    onRemove={() => {
                                        setBackgroundImageUrl('');
                                    }}
                                    instructions="Drag & drop or choose an image for the chat background"
                                    formats={['image/jpeg', 'image/png']}
                                />}
                        </div>
                    </div>

                    <div className='RagChatbotDetailPage__row'>
                        <div className='RagChatbotDetailPage__input-wrap'>
                            <div className='RagChatbotDetailPage__input-label'>Widget Icons
                                <Tooltip tooltip={"Icons displayed for the widget's open and close states."}>
                                    <InfoIcon />
                                </Tooltip>
                            </div>
                            <div className='RagChatbotDetailPage__widget-icons'>
                                <div className='RagChatbotDetailPage__widget-icon'>
                                    <label>Open Icon</label>
                                    {uploadingImage === 'widget-open' ? <Loader />
                                        : <ImageInput
                                            name="widget_icon_open"
                                            value={widgetIconOpen}
                                            onChange={(file) => onImageUpload(file, 'widget-open')}
                                            onRemove={() => {
                                                setWidgetIconOpen('');
                                            }}
                                            instructions="Choose widget open icon"
                                            formats={['image/jpeg', 'image/png', 'image/gif']}
                                        />}
                                </div>
                                <div className='RagChatbotDetailPage__widget-icon'>
                                    <label>Close Icon</label>
                                    {uploadingImage === 'widget-close' ? <Loader />
                                        : <ImageInput
                                            name="widget_icon_close"
                                            value={widgetIconClose}
                                            onChange={(file) => onImageUpload(file, 'widget-close')}
                                            onRemove={() => {
                                                setWidgetIconClose('');
                                            }}
                                            instructions="Choose widget close icon"
                                            formats={['image/jpeg', 'image/png', 'image/gif']}
                                        />}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='RagChatbotDetailPage__row'>
                        <div className='RagChatbotDetailPage__input-wrap'>
                            <div
                                className='RagChatbotDetailPage__input-label collapsible'
                                onClick={() => setShowColorSettings(!showColorSettings)}
                            >
                                Chatbot Colors {showColorSettings ? '▼' : '►'}
                            </div>
                            {showColorSettings && (
                                <div className='RagChatbotDetailPage__color-settings-container'>
                                    <div className='RagChatbotDetailPage__color-picker'>
                                        <label>Apply color theme</label>
                                        <select
                                            onChange={(e) => handleThemeChange(e.target.value)}
                                            className='RagChatbotDetailPage__color-theme-selector'
                                            defaultValue=""
                                        >
                                            <option value="" disabled>Choose a theme to apply</option>
                                            <option value="default">Dark</option>
                                            <option value="light">Light</option>
                                            {/* <option value="vgk">VGK</option> */}
                                        </select>
                                    </div>
                                    <div className='RagChatbotDetailPage__color-settings'>
                                        {ChatbotColors.map(({ key }) => (
                                            <div key={key} className='RagChatbotDetailPage__color-picker'>
                                                <label>{getReadableColorName(key)}</label>
                                                <ColorPicker
                                                    color={colors[key]}
                                                    setColor={(color) => handleColorChange(key, color)}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>)}
                        </div>
                    </div>
                </>
            )}

            {activeTab === 'privacy' && (
                <>
                    <div className='RagChatbotDetailPage__row'>
                        <div className='RagChatbotDetailPage__input-wrap'>
                            <div className='RagChatbotDetailPage__input-label'>Analytics Tracking
                                <Tooltip tooltip={"The stats tab won't receive data if tracking is disabled"}>
                                    <InfoIcon />
                                </Tooltip>
                            </div>
                            <Checkbox
                                label="Disable tracking and analytics"
                                checked={disableAnalytics}
                                onChange={(e) => setDisableAnalytics(e.target.checked)}
                            />
                        </div>
                    </div>
                </>
            )}

            {activeTab === 'feedbacks' && (
                <>
                    <div className='RagChatbotDetailPage__row'>
                        <div className='RagChatbotDetailPage__input-wrap'>
                            <div className='RagChatbotDetailPage__input-label'>Response Feedback
                                <Tooltip tooltip={"Allow users to rate chatbot responses with thumbs up/down"}>
                                    <InfoIcon />
                                </Tooltip>
                            </div>
                            <Checkbox
                                label="Enable user feedback on chatbot responses"
                                checked={enableFeedback}
                                onChange={(e) => setEnableFeedback(e.target.checked)}
                            />
                        </div>
                    </div>

                    {enableFeedback && (
                        <div className='RagChatbotDetailPage__row'>
                            <div className='RagChatbotDetailPage__input-wrap slider'>
                                <div className='RagChatbotDetailPage__input-label'>Feedback Frequency
                                    <Tooltip tooltip={"Percentage of responses that will show feedback options. Set to 100% to show on all responses."}>
                                        <InfoIcon />
                                    </Tooltip>
                                </div>
                                <Slider 
                                    className='RagChatbotDetailPage__slider-wrap' 
                                    step={1} 
                                    id="feedbackRateId"
                                    max={100} 
                                    min={0} 
                                    value={feedbackSamplingRate} 
                                    onChange={(e) => setFeedbackSamplingRate(Number(e.target.value))} 
                                />
                            </div>
                        </div>
                    )}
                </>
            )}

            <div className='RagChatbotDetailPage__row center'>
                <Button color="green" title={"Update"} onClick={handleUpdate} size={'large'} loading={loading} disabled={loading} />
            </div>
        </div>
    </div>
}

const CustomOption = ({ data, customEndpoints, selectedId, ...props }) => {
    const dispatch = useDispatch();
    const { projectId, id } = useParams();
    const onEdit = (e, endpoints) => {
        e.stopPropagation();
        const endpoint = _.get(endpoints, 0);

        dispatch(UIState.actions.showModal(ModalTypes.UPDATE_LLM_ENDPOINT, { type: 'edit', endpoint, projectId, chatbotId: id }))
        return;
    }
    const onDelete = (e, endpoints) => {
        e.stopPropagation();
        const endpoint = _.get(endpoints, 0);
        const onConfirm = async () => {
            await dispatch(Ai.actions.deleteLlmEndpoint(projectId, id, endpoint.id));
            await dispatch(Ai.actions.fetchRagChatbot(projectId, id));
        }
        dispatch(UIState.actions.showModal(ModalTypes.DELETE, { title: endpoint.name || endpoint.id, onConfirm }));
    }
    return (
        <components.Option {...props}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: data.type === 'custom' ? 'space-between' : 'flex-start' }}>
                {data.value === 'add-custom' && <GrAdd style={{ marginRight: 5 }} />}
                <span>{data.label}</span>
                {data.type === 'custom' && <div>
                    <Button color='green-outline' title="Edit" size='small'
                        onClick={(e) => onEdit(e, customEndpoints.filter(e => e.id === data.value))} />
                    <Button color='red-outline' title="Remove" size='small' style={{ marginLeft: 5 }}
                        disabled={data.value === selectedId}
                        onClick={(e) => onDelete(e, customEndpoints.filter(e => e.id === data.value))} />
                </div>}
            </div>
        </components.Option>
    );
}


const QuestionsSection = ({ questions, setQuestions }) => {

    const handleQuestionChange = (index, value) => {
        const updatedQuestions = [...questions];
        updatedQuestions[index].question = value;
        setQuestions(updatedQuestions);
    };

    const handleAnswerChange = (index, value) => {
        const updatedQuestions = [...questions];
        updatedQuestions[index].answer = value;
        setQuestions(updatedQuestions);
    };

    return <div className='RagChatbotDetailPage__questions'>
        {questions.map((q, i) => {
            return <div className='RagChatbotDetailPage__question-container' key={i}>
                <div className='RagChatbotDetailPage__question'>
                    <div className='RagChatbotDetailPage__question--wrap'>
                        <div className='RagChatbotDetailPage__question--label'>Question #{i + 1}</div>
                        <input className='RagChatbotDetailPage__question--input'
                            type="text" placeholder={`Enter question #${i + 1}`}
                            value={q.question}
                            onChange={(e) => handleQuestionChange(i, e.target.value)} />
                    </div>
                    <div className='RagChatbotDetailPage__question--wrap'>
                        <div className='RagChatbotDetailPage__question--label'>Answer #{i + 1}</div>
                        <input className='RagChatbotDetailPage__question--input'
                            type="text" placeholder={`Enter answer #${i + 1}, or leave blank if you want the chatbot to generate the answer dynamically`}
                            value={q.answer}
                            onChange={(e) => handleAnswerChange(i, e.target.value)} />
                    </div>
                </div>
                <div className='RagChatbotDetailPage__question-icon-container'>
                    <div className='RagChatbotDetailPage__question-delete-icon'
                        onClick={() => setQuestions(pre => pre.filter((_, idx) => idx !== i))}>
                        <DeleteIcon />
                    </div>
                </div>
            </div>
        })}
    </div >
}


const WarmupMessagesSection = ({ questions, setQuestions }) => {
    const roleOptions = [
        { value: 'user', label: 'User' },
        { value: 'assistant', label: 'Assistant' },
    ];

    const handleMessageChange = (index, value) => {
        const updatedQuestions = [...questions];
        updatedQuestions[index].content = value;
        setQuestions(updatedQuestions);
    };

    const handleRoleChange = (index, selectedOption) => {
        const updatedQuestions = [...questions];
        updatedQuestions[index].role = selectedOption.value;
        setQuestions(updatedQuestions);
    };

    return <div className='RagChatbotDetailPage__questions'>
        {questions.map((q, i) => {
            return <div className='RagChatbotDetailPage__question-container' key={i}>
                <div className='RagChatbotDetailPage__question'>
                    <div className='RagChatbotDetailPage__question--wrap warmup'>
                        <input className='RagChatbotDetailPage__question--input'
                            type="text" placeholder={`Enter message #${i + 1}`}
                            value={q.content}
                            onChange={(e) => handleMessageChange(i, e.target.value)} />
                        <SelectInput
                            options={roleOptions}
                            value={roleOptions.find(option => option.value === q.role)}
                            onChange={(value) => handleRoleChange(i, value)}
                            styles={selectStyles}
                            components={{ Option: (props) => <CustomOption {...props} selectedId={q.role} /> }}
                        />
                    </div>
                </div>
                <div className='RagChatbotDetailPage__question-icon-container'>
                    <div className='RagChatbotDetailPage__question-delete-icon'
                        onClick={() => setQuestions(pre => pre.filter((_, idx) => idx !== i))}>
                        <DeleteIcon />
                    </div>
                </div>
            </div>
        })}
    </div>
}

const ColorPicker = (props) => {
    const { color, setColor } = props;
    const [displayColorPicker, setDisplayColorPicker] = useState(false);

    const handleClick = () => {
        setDisplayColorPicker(v => !v)
    }

    const handleClose = () => {
        setDisplayColorPicker(false);
    }

    const handleChange = (color) => {
        setColor(color.hex)
    }

    return <div className='RagChatbotDetailPage__color'>
        <div className='RagChatbotDetailPage__color--swatch' onClick={handleClick}>
            <div className='RagChatbotDetailPage__color--color' style={{ background: color }} />
            <div className='RagChatbotDetailPage__color--hexcode'>{color}</div>
        </div>
        {displayColorPicker && <div className='RagChatbotDetailPage__color--popover'>
            <div className='RagChatbotDetailPage__color--cover' onClick={handleClose} />
            <ChromePicker color={color} onChange={handleChange} />
        </div>}
    </div>
}


const selectStyles = {
    container: (styles, { isDisabled }) => ({
        ...styles,
        flex: 1,
        height: 40,
        cursor: isDisabled ? 'not-allowed' : 'default',
    }),
    control: (styles, { isDisabled }) => ({
        ...styles,
        paddingLeft: 15,
        backgroundColor: '#191D29',
        borderColor: '#3D4463',
        borderRadius: 6,
        ':hover': {
            borderColor: 'white',
        },
        '.selected': {
            borderColor: 'white',
        },
    }),
    menu: (styles, state) => ({
        ...styles,
        border: '1px solid #3D4463',
        backgroundColor: '#191D29',
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: '#191D29',
            borderColor: '#3D4463',
            color: isDisabled ? '#636B91' : (isFocused ? 'white' : (isSelected ? 'white' : '#636B91')),
            opacity: isDisabled ? 0.5 : 1,
        };
    },
    dropdownIndicator: (styles, state) => ({
        ...styles,
        color: '#8A8FB5',
    }),
    indicatorsContainer: (styles, { isDisabled, hasValue, getValue }) => ({
        ...styles,
        display: isDisabled ? 'none' : 'flex',
        '> div:first-of-type': {
            display: (hasValue && getValue()[0]?.value === 'default') ? 'none' : 'flex'
        }
    }),
    indicatorSeparator: (styles, state) => ({
        ...styles,
        color: '#636B91',
        backgroundColor: '#636B91',
        display: 'none'
    }),
    input: styles => ({ ...styles, color: "#fff", height: 30 }),
    placeholder: styles => ({ ...styles, color: '#636B91' }),
    singleValue: (styles, { data }) => ({ ...styles, color: '#fff', fontWeight: '500' }),
    multiValue: (styles) => ({
        ...styles,
        backgroundColor: '#636B91',
        borderRadius: '2px',
        padding: '2px 4px 2px 4px',
        fontWeight: '500',
    }),
    multiValueLabel: (styles) => ({
        ...styles,
        color: '#fff',
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: '#fff',
        paddingLeft: '4px',
        display: data.value === 'default' ? 'none' : 'flex',
        ':hover': {
            backgroundColor: '#838BB1',
            color: '#fff',
        },
    }),
};

