import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../Button';
import UIState from '../../store/UIState';
import { ModalTypes } from '../../constants';
import ChatbotTools from '../../store/models/RagChatbot/ChatbotTools';
import cx from 'classnames';
import Loader from '../Loader';
import { DropdownMenu, DropdownMenuItem } from '../DropdownMenu';
import { VerticalDotsIcon } from '../Svg';
import { toast } from 'react-toastify';
import { Toggle } from '../form/Toggle';
import Ai from '../../store/models/Ai';
import _ from 'lodash';
import { MOCK_TOOLS } from '../../store/mockData/chatbotTools';  // Keep for reference
import Tooltip from '../Tooltip';
import { selectChatbotTools } from '../../store/models/RagChatbot/ChatbotTools';

const ITEMS_PER_PAGE = 100;
const TAB_CONFIG = {
  custom: {
    label: 'Custom Tools',
    description: 'Create and manage your custom API tools'
  },
  web: {
    label: 'Web Search',
    description: 'Enable web search capabilities'
  },
  sql: {
    label: 'Text-to-SQL',
    description: 'Convert natural language to SQL queries'
  },
  widget: {
    label: 'Custom Widget',
    description: 'Customize widget appearance and behavior'
  }
};

export const RagToolsTab = ({ chatbot, projectId }) => {
  const [tab, setTab] = useState('custom');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const observerRef = useRef();
  const loadingRef = useRef();
  const dispatch = useDispatch();

  // Get tools from Redux store
  const tools = useSelector(state => selectChatbotTools(state, chatbot?.id));

  // Initialize web search and sql states from chatbot settings
  const [webSearchEnabled, setWebSearchEnabled] = useState(false);

  const [sqlEnabled, setSqlEnabled] = useState(false);

  // Add intersection observer setup
  const lastToolElementRef = useCallback(node => {
    if (loading) return;
    if (observerRef.current) observerRef.current.disconnect();
    
    observerRef.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        fetchMoreTools();
      }
    });

    if (node) observerRef.current.observe(node);
  }, [loading, hasMore]);

  // Add a state to track if initial load is done
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (chatbot && !isInitialized) {
      setIsInitialized(true);
      fetchTools();
      
      if (chatbot.settings && chatbot.settings['agent.tools']) {
        setWebSearchEnabled(chatbot.settings['agent.tools'].web_search || false);
        setSqlEnabled(chatbot.settings['agent.tools'].text_to_sql || false);
      }
    }
  }, [chatbot, isInitialized]);

  const fetchTools = async () => {
    try {
      setLoading(true);
      setError(null);
      setOffset(0);
      
      const result = await dispatch(ChatbotTools.actions.getChatbotTools(
        projectId, 
        chatbot.id,
        0,
        ITEMS_PER_PAGE
      ));
      
      if (result) {
        setHasMore(result.items.length === ITEMS_PER_PAGE);
        setOffset(ITEMS_PER_PAGE);
      }
    } catch (error) {
      console.error('Failed to fetch tools:', error);
      toast.error('Failed to fetch tools');
      setError(error.message);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  const fetchMoreTools = async () => {
    if (!hasMore || loading) return;

    try {
      setLoading(true);
      
      const result = await dispatch(ChatbotTools.actions.getChatbotTools(
        projectId,
        chatbot.id,
        offset,
        ITEMS_PER_PAGE
      ));
      
      if (result) {
        setHasMore(result.items.length === ITEMS_PER_PAGE);
        setOffset(prev => prev + ITEMS_PER_PAGE);
      }
    } catch (error) {
      console.error('Failed to fetch more tools:', error);
      toast.error('Failed to fetch more tools');
    } finally {
      setLoading(false);
    }
  };

  const openCreateToolModal = () => {
    dispatch(UIState.actions.showModal(ModalTypes.CREATE_CHATBOT_TOOL, {
      chatbotId: chatbot.id,
    }));
  };

  const handleEditTool = (tool, e) => {
    e.stopPropagation();
    dispatch(UIState.actions.showModal(ModalTypes.CREATE_CHATBOT_TOOL, {
      chatbotId: chatbot.id,
      tool,
    }));
  };

  const handleDeleteTool = (tool, e) => {
    dispatch(UIState.actions.showModal(ModalTypes.DELETE, {
      title: `${tool.name} tool`,
      message: 'Are you sure you want to delete this tool?',
      onConfirm: () => onConfirmDeleteTool(tool),
    }));
  };

  const onConfirmDeleteTool = async (tool) => {
    try {
      await dispatch(ChatbotTools.actions.deleteChatbotTool(projectId, chatbot.id, tool.id));
    } catch (error) {
      console.error('Failed to delete tool:', error);
      toast.error('Failed to delete tool');
    }
  };

  // Add update method
  const updateChatbotTools = async (tools) => {
    try {
      setLoading(true);
      const updatedChatbot = {
        ...chatbot,
        settings: {
          ...chatbot.settings,
          'agent.tools': {
            ..._.get(chatbot, 'settings.agent.tools', {}),
            ...tools
          }
        }
      };

      await dispatch(Ai.actions.updateRagChatbot(chatbot.id, updatedChatbot));
      await dispatch(Ai.actions.fetchRagChatbot(projectId, chatbot.id));
      toast.success('Tool settings updated successfully');
    } catch (error) {
      console.error('Failed to update tool settings:', error);
      toast.error('Failed to update tool settings');
    } finally {
      setLoading(false);
    }
  };

  // Update handlers to use the new update method
  const handleWebSearchToggle = async (e) => {
    const enabled = e.target.checked;
    setWebSearchEnabled(enabled);
    await updateChatbotTools({ web_search: enabled });
  };

  const handleSqlToggle = async (e) => {
    const enabled = e.target.checked;
    setSqlEnabled(enabled);
    await updateChatbotTools({ text_to_sql: enabled });
  };

  const handleDuplicateTool = (tool, e) => {
    e.stopPropagation();
    const duplicatedTool = {
      ...tool,
      name: `Duplicate - ${tool.name}`,
      id: undefined  // Remove ID so it creates a new tool
    };
    
    dispatch(UIState.actions.showModal(ModalTypes.CREATE_CHATBOT_TOOL, {
      chatbotId: chatbot.id,
      tool: duplicatedTool,
      onSuccess: fetchTools
    }));
  };

  return (
    <div className='RagChatbotDetailPage row'>
      <div className={`RagChatbotDetailPage__tabs ${tab === 'custom' ? 'RagChatbotDetailPage__tabs--for-list' : ''}`}>
        {Object.entries(TAB_CONFIG).map(([key, { label }]) => (
          <div
            key={key}
            className={cx("RagChatbotDetailPage__tab", { active: tab === key })}
            onClick={() => setTab(key)}
          >
            {label}
          </div>
        ))}
      </div>

      <div className={`${tab === 'custom' ? 'RagChatbotDetailPage__tab-content-blank' : 'RagChatbotDetailPage__tab-content'}`}>
        {tab === 'custom' && (
          <>
            <div className='RagChatbotDetailPage__tools-container'>
              <div className='RagChatbotDetailPage__tools-header'>
                <div className='RagChatbotDetailPage__tools-header-name'>Tool Name</div>
                <div className='RagChatbotDetailPage__tools-header-id'>ID</div>
                <div className='RagChatbotDetailPage__tools-header-status'>Status</div>
                <div className='RagChatbotDetailPage__tools-header-actions'>
                  {tools.length > 0 && (
                    <Tooltip tooltip="Create new custom tool">
                      <Button
                        color="green"
                        onClick={openCreateToolModal}
                        title="New"
                        className="RagChatbotDetailPage__add-button"
                      />
                    </Tooltip>
                  )}
                </div>
              </div>

              <div className="RagChatbotDetailPage__tools-list">
                {error ? (
                  <div className='RagChatbotDetailPage__tab-empty'>
                    <div className='RagChatbotDetailPage__tab-empty-error'>Error loading tools</div>
                    <Button
                      color="green"
                      onClick={fetchTools}
                      title="Retry"
                    />
                  </div>
                ) : tools.length > 0 ? (
                  <>
                    {tools.map((tool, index) => {
                      const isLastElement = index === tools.length - 1;
                      
                      return (
                        <div 
                          key={tool.id} 
                          ref={isLastElement ? lastToolElementRef : null}
                          className='RagChatbotDetailPage__tools-item' 
                          onClick={(e) => handleEditTool(tool, e)}
                        >
                          <div className='RagChatbotDetailPage__tools-item-name'>{tool.name}</div>
                          <div className='RagChatbotDetailPage__tools-item-id'>{tool.id}</div>
                          <div className='RagChatbotDetailPage__tools-item-status'>
                            {tool.enabled ? 'Active' : 'Inactive'}
                          </div>
                          <div className='RagChatbotDetailPage__tools-item-actions' onClick={(e) => e.stopPropagation()}>
                            <DropdownMenu icon={<VerticalDotsIcon />}>
                              <DropdownMenuItem
                                label="Edit"
                                onClick={(e) => handleEditTool(tool, e)}
                              />
                              <DropdownMenuItem
                                label="Duplicate"
                                onClick={(e) => handleDuplicateTool(tool, e)}
                              />
                              <DropdownMenuItem
                                label="Delete"
                                onClick={(e) => handleDeleteTool(tool, e)}
                              />
                            </DropdownMenu>
                          </div>
                        </div>
                      );
                    })}

                    {loading && (
                      <div className='RagChatbotDetailPage__tools-loading'>
                        <Loader />
                      </div>
                    )}
                  </>
                ) : !loading ? (
                  <div className='RagChatbotDetailPage__tab-empty'>
                    <h3>No Custom Tools</h3>
                    <Button
                      color="green"
                      onClick={openCreateToolModal}
                      title="Add First Custom Tool"
                    />
                  </div>
                ) : (
                  <div className='RagChatbotDetailPage__tab-empty'>
                    <Loader />
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        {tab === 'widget' && (
          <div className='RagChatbotDetailPage__tab-empty'>
            <h3>Coming Soon</h3>
          </div>
        )}

        {tab === 'web' && (
          <div className='RagChatbotDetailPage__toggle-wrapper'>
            <div className='RagChatbotDetailPage__toggle-wrapper--info'>
              Allow your chatbot to search the internet for up-to-date information and enhance its responses
            </div>
            <Toggle
              checked={webSearchEnabled}
              onChange={handleWebSearchToggle}
              disabled={loading}
            />
          </div>
        )}

        {tab === 'sql' && (
          <div className='RagChatbotDetailPage__toggle-wrapper'>
            <div className='RagChatbotDetailPage__toggle-wrapper--info'>
              Allow your chatbot to convert natural language questions into SQL queries for database analysis
            </div>
            <Toggle
              checked={sqlEnabled}
              onChange={handleSqlToggle}
              disabled={loading}
            />
          </div>
        )}

      </div>
    </div>
  );
};
