export const AiShowcasesPrivacyPolicyPage = () => {

    return (<div className="AiShowcasesTermsPage">
        <h1>PRIVACY POLICY</h1>
        <div className="lastUpdated">Last Updated: 3.18.25</div>
        <p>Theta Labs, Inc. ("Theta," "we" or "us") is committed to protecting and respecting your privacy. This privacy policy (this "Policy") sets out how we collect and process personal information about you when you visit our website at <a href="https://www.thetaedgecloud.com">https://www.thetaedgecloud.com</a>, when you sign up for or use the site, or when you otherwise use our services ("Services"), do business or make contact with us.</p>
        <p>Your privacy matters to us, so please do take the time to get to know and familiarize yourself with our policies and practices. Please understand that we reserve the right to change any of our policies and practices at any time, but you can always find the latest version of this Privacy Policy here on this page.</p>
        <h2>PERSONAL INFORMATION WE COLLECT</h2><p>As used herein, "Personal Information" means information that identifies or is reasonably capable of identifying an individual, directly or indirectly, and information that is capable of being associated with an identified or reasonably identifiable individual. </p>
        <p><strong>Personal Information we collect from you</strong>. We may collect the following categories of Personal Information directly from you:</p>
        <ul>
            <li>Identification Information, such as name, email, phone number, postal address;   </li>
            <li>Commercial Activity, such as trading activity, order activity, deposits, withdrawals, account balances;   </li>
            <li>Correspondence, such as information you provide to us in correspondence, including account opening and customer support; and   </li>
            <li>Sensory Information, such as images that you upload to your User Profile.</li>
        </ul>
        <p><strong>Personal Information we collect automatically</strong>. We may collect the following categories of Personal Information automatically through your use of our services:</p>
        <ul>
            <li>Online Identifiers, such as IP address, domain name;   </li>
            <li>Device Information, such as hardware, operating system, browser;   </li>
            <li>Usage Data, such as system activity, internal and external information related to Theta EdgeCloud pages that you visit, clickstream information; and   </li>
            <li>Geolocation Data.</li>
        </ul>
        <p>Our automatic collection of Personal Information may involve the use of Cookies, described in greater detail below. </p>
        <p><strong>Personal Information we collect from third parties</strong>. We may collect and/or verify the following categories of Personal Information about you from third parties:</p>
        <ul>
            <li>Identification Information, such as name, email, phone number, postal address   </li>
            <li>Transaction Information, such as public blockchain data (bitcoin, ether, and other Digital Assets are not truly anonymous. We, and any others who can match your public Digital Asset address to other Personal Information about you, may be able to identify you from a blockchain transaction because, in some circumstances, Personal Information published on a blockchain (such as your Digital Asset address and IP address) can be correlated with Personal Information that we and others may have. Furthermore, by using data analysis techniques on a given blockchain, it may be possible to identify other Personal Information about you);   </li>
            <li>Financial Information, such as bank account information, routing number, credit card number, debit card number; and   </li>
            <li>Additional Information, at our discretion to comply with legal obligations.</li>
        </ul>
        <p><strong>Accuracy and retention of Personal Information</strong>. We take reasonable and practicable steps to ensure that your Personal Information held by us is (i) accurate with regard to the purposes for which it is to be used, and (ii) not kept longer than is necessary for the fulfillment of the purpose for which it is to be used.</p>
        <p><strong>How We Use Your Personal Information</strong>.  We collect Personal Information about you in an attempt to provide you with the best experience possible, protect you from risks related to improper use and fraud, and help us maintain and improve our Services. We may use your Personal Information to:</p>
        <ul>
            <li>Provide you with our Services. We use your Personal Information to provide you with our Services pursuant to the terms of our Terms of Use. For example, in order to facilitate fiat transfers out of your account, we need to know your financial information.   </li>
            <li>Comply with legal and regulatory requirements. We process your Personal Information as required by applicable laws and regulations.   </li>
            <li>Detect and prevent fraud. We process your Personal Information to detect and prevent fraud on your account, which is especially important given the irreversible nature of cryptocurrency transactions.   </li>
            <li>Protect the security and integrity of our Services. We use your Personal Information, including information about your device and your activity on Theta EdgeCloud to maintain the security of your account and the Theta EdgeCloud platform.   </li>
            <li>Provide you with customer support. We process your Personal Information when you contact our support team with questions about or issues with your account.   </li>
            <li>Market our products. We may contact you with information about our Services. We will only do so with your permission, which can be revoked at any time.   </li>
            <li>Other business purposes. We may use your Personal Information for additional purposes if that purpose is disclosed to you before we collect the information or if we obtain your consent. </li>
        </ul>
        <h2>HOW WE SHARE YOUR PERSONAL INFORMATION</h2><p>We will not share your Personal Information with third parties, except as described below.</p>
        <p><strong>Service Providers</strong>. We may share your Personal Information with third-party service providers for business or commercial purposes, including fraud detection and prevention, security threat detection, payment processing, customer support, data analytics, Information Technology, advertising and marketing, network infrastructure, storage, transaction monitoring. We share your Personal Information with these service providers only so that they can provide us with the services, and we prohibit our service providers from using or disclosing your Personal Information for any other purpose. You authorize us to collect and share with our payment providers Stripe and BitPay your Personal Information including full name, email address and financial information, and you are responsible for the accuracy and completeness of that data. Stripe's Privacy Policy is available <a href="https://stripe.com/privacy">here</a> and BitPay's Privacy Notice is available <a href="https://bitpay.com/about/privacy/">here</a>. </p>
        <p><strong>Third Parties</strong>. Our Services may embed or contain links to other third party websites or services that we do not own or operate. Please be aware that are not responsible for these third parties' privacy practices, and that this Policy does not apply to your activities or any information you disclose to these third parties. We encourage you to read their privacy policies carefully before sharing any information with them. </p>
        <p><strong>Law Enforcement</strong>. We may be compelled to share your Personal Information with law enforcement, government officials, and regulators. </p>
        <p><strong>Corporate Transactions</strong>. We may disclose Personal Information in the event of a proposed or consummated merger, acquisition, reorganization, asset sale, or similar corporate transaction, or in the event of a bankruptcy or dissolution. </p>
        <p><strong>Professional Advisors</strong>. We may share your Personal Information with our professional advisors, including legal, accounting, or other consulting services for purposes of audits or to comply with our legal obligations. </p>
        <p><strong>Consent</strong>. We may share your Personal Information with your consent.</p>
        <p>If we decide to modify the purpose for which your Personal Information is collected and used, we will amend this Privacy Policy. </p>
        <p><strong>Cookies</strong>. When you access Theta EdgeCloud, we may make use of the standard practice of placing tiny data files called cookies, flash cookies, pixel tags, or other tracking tools (herein, "Cookies") on your computer or other devices used to visit Theta EdgeCloud. We use Cookies to help us recognize you as a customer, collect information about your use of Theta EdgeCloud to better customize our services and content for you, and collect information about your computer or other access devices to: (i) ensure that your account security has not been compromised by detecting irregular, suspicious, or potentially fraudulent account activities; (ii) assess and improve our services and advertising campaigns. You also can learn more about cookies by visiting <a href="http://www.allaboutcookies.org">http://www.allaboutcookies.org</a>, which includes additional useful information on cookies and how to block cookies on different types of browsers and mobile devices. Please note that if you reject cookies, you will not be able to use some or all of Theta EdgeCloud. If you do not consent to the placing of Cookies on your device, please do not visit, access, or use Theta EdgeCloud. </p>
        <p><strong>Direct Marketing</strong>.  Subject to applicable laws and regulations, we may from time to time send direct marketing materials promoting services, products, facilities, or activities to you using information collected from or about you. You may also opt-out of such communications by following the directions provided in any marketing communication. It is our policy to not provide your Personal Information for those third parties' direct marketing purposes without your consent. Information </p>
        <h2>SECURITY</h2><p>No security is foolproof, and the Internet is an insecure medium. We cannot guarantee absolute security, but we work hard to protect Theta EdgeCloud and you from unauthorized access to or unauthorized alteration, disclosure, or destruction of Personal Information we collect and store. Measures we take include encryption of the Theta EdgeCloud website communications with SSL; optional two-factor authentication; periodic review of our Personal Information collection, storage, and processing practices; and restricted access to your Personal Information on a need-to-know basis for our employees, contractors and agents who are subject to strict contractual confidentiality obligations and may be disciplined or terminated if they fail to meet these obligations. </p>
        <h2>INFORMATION FOR PERSONS SUBJECT TO EU DATA PROTECTION LAW</h2><p>While customers who are located in the European Union ("EU"), European Economic Area ("EEA") or the Channel Islands, or other locations subject to EU data protection law (collectively, "Europe") are customers of our US entity, we recognize and, to the extent applicable to us, adhere to relevant EU data protection laws. For purposes of this section, "personal data" has the meaning provided in the General Data Protection Regulation (EU) 2016/679 ("GDPR"). </p>
        <p><strong>Lawful bases for processing</strong>. We process personal data subject to GDPR on one or more of the following legal bases:</p>
        <ul>
            <li>Legal Obligation: to conduct anti-fraud and to fulfill our retention and other legal obligations; Contractual Obligation: to satisfy our obligations to you under our Terms of Use, including to provide you with our Services and customer support services, and to optimize and enhance Theta EdgeCloud;   </li>
            <li>Legitimate Interest: to monitor the usage of Theta EdgeCloud, conduct automated and manual security checks of our Services, to protect our rights; and   </li>
            <li>Consent: to market Theta EdgeCloud and our Services. You may withdraw your consent at any time without affecting the lawfulness of processing based on consent before consent is withdrawn. </li>
        </ul>
        <p><strong>European privacy rights</strong>. European residents have the following rights under GDPR, subject to certain exceptions provided under the law, with respect to their personal data:</p>
        <ul>
            <li>Rights to Access and Rectification. You may submit a request that Theta EdgeCloud disclose the personal data that we process about you and correct any inaccurate personal data.   </li>
            <li>Right to Erasure. You may submit a request that Theta EdgeCloud delete the personal data that we have about you.   </li>
            <li>Right to Restriction of Processing. You have the right to restrict or object to our processing of your personal data under certain circumstances.   </li>
            <li>Right to Data Portability. You have the right to receive the personal data you have provided to us in an electronic format and to transmit that personal data to another data controller.</li>
        </ul>
        <p>To submit a request to exercise these rights, please contact us using the methods described at the end of this Privacy Policy. When handling requests to exercise European privacy rights, we check the identity of the requesting party to ensure that he or she is the person legally entitled to make such request. While we maintain a policy to respond to these requests free of charge, should your request be repetitive or unduly onerous, we reserve the right to charge you a reasonable fee for compliance with your request. </p>
        <p><strong>Collection and transfer of data outside the EEA</strong>. Theta EdgeCloud operates with many of our systems based in the United States. As a result, we may transfer personal data from Europe to third countries outside of Europe, including the United States, under the following conditions: </p>
        <ul>
            <li>Contractual Obligation. Where transfers are necessary to satisfy our obligation to you under our Terms of Use, including to provide you with our Services and customer support services, and to optimize and enhance Theta EdgeCloud; and   </li>
            <li>Consent: where you have consented to the transfer of your personal data to a third country.</li>
        </ul>
        <p>Where transfers to a third country are based on your consent, you may withdraw your consent at any time. Please understand, however, that our services may not be available if we are unable to transfer personal data to third countries.</p>
        <p>When we transfer personal data to third countries, we endeavor to ensure adequate safeguards are implemented, for example through the use of standard contractual clauses or Privacy Shield certification. </p>
        <p>Automated decision-making. We may engage in automated decision-making for purposes of fraud detection and prevention.</p>
        <h2>CALIFORNIA RESIDENT RIGHTS</h2><p>If you are a California resident, you have the rights set forth in this section. Please see the "Exercising Your Rights" section below for instructions regarding how to exercise these rights. If there are any conflicts between this section and any other provision of this Privacy Policy and you are a California resident, the portion that is more protective of your personally-identifiable data will control to the extent of such conflict. If you have any questions about this section or whether any of the following rights apply to you, please contact us at support@thetatoken.org.</p>
        <h2>EXERCISING YOUR RIGHTS</h2><p>Please follow the instructions and requirements described below when submitting your requests. Requests that fail to comply with any of these instructions and requirements may result in delayed or no response.</p>
        <p>To exercise the rights described below as a California resident, you must send us a request (1) that provides sufficient information to allow us to verify that (i) you are the person about whom we have collected personal data, (ii) you, as the requester, are the same person as the data subject for whose information you're requesting (or such person's parent/guardian), (2) that describes your request in sufficient detail to allow us to understand, evaluate and respond to it, (3) that declares, under the penalty of perjury, that you're exercising your rights under the CCPA as a California resident solely for lawful purposes, and (4) in a way that does not and would not unduly burden or otherwise abuse our data request system and/or Theta EdgeCloud. Each request that meets all of these criteria, and which is confirmed via email verification, will be considered a "Valid Request." We may not respond to requests that do not meet these criteria. We will use commercially reasonable efforts to determine whether a request may be for harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or otherwise objectionable purposes, and we reserve the right not to respond to such request. We will only use your personal data provided in a Valid Request to verify your identity and complete your request. You do not need an account on Theta EdgeCloud to submit a Valid Request.</p>
        <p>We will work to respond to your Valid Request within 45 days of receipt. We will not charge you a fee for making a Valid Request unless your Valid Request(s) is excessive, repetitive or manifestly unfounded. If we determine that your Valid Request warrants a fee, we will notify you of the fee and explain that decision before completing your request.</p>
        <p>You may submit a Valid Request by emailing us at: support@thetatoken.org.</p>
        <p>You may also authorize an agent (an "Authorized Agent") to exercise your rights on your behalf. To do this, you must provide your Authorized Agent with written permission to exercise your rights on your behalf, and we may request a copy of this written permission from your Authorized Agent when they make a request on your behalf.</p>
        <h2>ACCESS</h2><p>You have the right to request certain information about our collection and use of your personal data over the past 12 months. In response to a Valid Request, we will provide you with the following information:</p>
        <ul>
            <li>The categories of personal data that you requested and that we have collected about you.  </li>
            <li>The categories of sources from which that personal data was collected.  </li>
            <li>The business or commercial purpose for collecting your personal data.  </li>
            <li>The categories of third parties with whom we have shared your personal data.  </li>
            <li>The specific pieces of Personal Data that you explicitly requested and that we have collected about you.</li>
        </ul>
        <p>If we have disclosed your personal data to any third parties for a business purpose over the past 12 months, we will identify the categories of personal data shared with each category of third party recipient.</p>
        <h2>DELETION</h2><p>You have the right to request that we delete the personal data that we have collected about you. Under the CCPA, this right is subject to certain exceptions: for example, we may need to retain your personal data to provide you with access to Theta EdgeCloud or complete a transaction or other action you have requested. If your deletion request is subject to one of these exceptions, we may deny your deletion request.</p>
        <p><strong>We Do Not Sell Your Personal Data</strong></p>
        <p>In this section, we use the term 'sell' as it is defined in the CCPA. We do not sell your personal data.</p>
        <p><strong>We Will Not Discriminate Against You for Exercising Your Rights Under the CCPA</strong></p>
        <p>We will not discriminate against you for exercising your rights under the CCPA. We will not deny you our goods or services, charge you different prices or rates, or provide you a lower quality of goods and services if you exercise your rights under the CCPA. However, we may offer different tiers of our products or services as allowed by applicable data privacy laws (including the CCPA) with varying prices, rates or levels of quality of the goods or services you receive related to the value of personal data that we receive from you.</p>
        <h2>HOW TO CONTACT US</h2><p>If you have a technical or support question, please contact support@thetatoken.org. If you have a privacy concern, complaint, or a question for Theta, please contact us by sending us an email at support@thetatoken.org. We will respond to questions or concerns within 30 days.<br />Unless otherwise stated, Theta Labs, Inc is a data controller for personal data we collect through Theta EdgeCloud subject to this statement. Our address is 2910 Stevens Creek Blvd., Ste. 200 San Jose, CA 95128.</p>
    </div>)
}
