import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { ServiceSidebarItem } from "../../../components/ServiceSidebarItem";
import { ServiceSidebarSubItem } from '../../../components/ServiceSidebarSubItem';
import { mergeUrl } from "../../../utils/url";
import { Urls, Features } from "../../../constants";
import { AgenticAiIcon, BetaIcon, PrototypingIcon, ServingIcon, TrainingIcon } from "../../../components/Svg";
import JupyterNotebookPage from './JupyterNotebookPage';
import ModelExplorerPage from './ModelExplorerPage';
import ModelDeploymentDetailPage from './ModelDeploymentDetailPage';
import ModelDeploymentsPage from './ModelDeploymentsPage';
import { selectCurrentUserId } from "../../../store/models/User";
import { selectCurrentProject, selectCurrentProjectId } from "../../../store/models/Project";
import Ai, { selectAllstandardTemplates } from '../../../store/models/Ai';

import { createSelector } from "reselect";
import GPUMarketingPage from './GPUMarketingPage';
import { selectCurrentOrg, selectFeaturesEnabled } from '../../../store/models/Organization';
import { Overlay } from '../../../components/Overlay';
import LlmDetailPage from './LlmdetailPage';
import GPUNodePage from './GPUNodePage';
import GpuNodeDetailPage from './GpuNodeDetailPage';
import RAGChatbotPage from './RAGChatbotPage';
import RagChatbotDetailPage from './RagChatbotDetailPage';
import AiStorageListPage from './storage/AiStorageListPage';
import StorageDetailsPage from './storage/StorageDetailsPage';
import { selectMobileLayout } from '../../../store/UIState';
import { SelectInput } from '../../../components/form/SelectInput';
import { pushTo } from '../../../store/history';
import { selectIsFeatureEnabled } from '../../../store/models/Organization';
import GpuNodeUpdatePage from './GpuNodeUpdatePage';

const selector = createSelector(
  selectCurrentUserId,
  selectCurrentProjectId,
  selectCurrentProject,
  selectCurrentOrg,
  selectFeaturesEnabled,
  selectMobileLayout,
  (userId, currentProjectId, currentProject, currentOrg, featuresEnabled, isMobile) => {
    return {
      userId,
      currentProjectId: currentProjectId || 'service',
      currentProject,
      currentOrg,
      featuresEnabled,
      isMobile
    }
  }
);

function AiServicesPage() {
  const dispatch = useDispatch();
  const { selectedTab } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const { userId, currentProjectId, currentProject, currentOrg, featuresEnabled, isMobile } = useSelector(state => selector(state));
  // console.log('currentOrg:', currentOrg)
  const standardTemplates = useSelector(state => selectAllstandardTemplates(state));

  // console.log('userId:', userId, ', currentProjectId:', currentProjectId, ", currentProject:", currentProject,)
  // console.log('standardTemplates:', standardTemplates)

  useEffect(() => {
    dispatch(Ai.actions.fetchstandardTemplates());
    dispatch(Ai.actions.fetchPrototypingTemplates());
    dispatch(Ai.actions.fetchTrainingTemplates());
    dispatch(Ai.actions.fetchVMs());
    dispatch(Ai.actions.fetchAllQuota())
    // if (_.isNil(userId)) return;
    // dispatch(Ai.actions.fetchCustomTemplates(currentProjectId));
  }, []);

  useEffect(() => {
    if (_.isNil(userId)) return;
    dispatch(Ai.actions.fetchCustomTemplates(currentProjectId, 1, 8));
  }, [userId, currentProjectId])

  const [selectedService, setSelectedService] = useState(selectedTab);

  const serviceOptions = [
    { value: 'model-explorer', label: 'Model Explorer', group: 'Serving' },
    { value: 'model-deployments', label: 'Model Deployments', group: 'Serving' },
    { value: 'jupyter-notebook', label: 'Jupyter Notebook', group: 'Prototyping' },
    { value: 'gpu-node', label: 'GPU Node', group: 'Training' },
    { value: 'storage', label: 'Persistent Storage', group: 'Training' },
    { value: 'rag-chatbot', label: 'RAG Chatbot', group: 'Agentic AI' },
  ];

  const handleServiceChange = (e) => {
    const newValue = e.target.value;
    setSelectedService(newValue);
    // Navigate to the corresponding URL
    window.location.href = mergeUrl(
      Urls[`AI_${newValue.replace(/-/g, '_').toUpperCase()}`],
      { projectId: currentProjectId }
    );
  };

  return (<div className={'ServiceContainer'}>
    {(!userId || (currentOrg && !currentOrg.disabled)) && <>
      {isMobile ? <>
        {serviceOptions.find(opt => opt.value === selectedService) && <div className="ServiceSidebar">
          <div className={'ServiceSidebar__title'}>THETA AI SERVICES</div>
          <SelectInput
            value={{ value: selectedService, label: serviceOptions.find(opt => opt.value === selectedService)?.label }}
            onChange={(selectedOption) => {
              const newValue = selectedOption.value;
              setSelectedService(newValue);
              pushTo(mergeUrl(
                Urls[`AI_${newValue.replace(/-/g, '_').toUpperCase()}`],
                { projectId: currentProjectId }
              ));
            }}
            options={serviceOptions.map((option) => ({
              value: option.value,
              label: `${option.group} - ${option.label}`
            }))}
            className="ServiceSidebar__select"
          />
        </div>}
      </> : (
        <div className='ServiceSidebar'>
          <div className={'ServiceSidebar__title'}>THETA AI SERVICES</div>
          <ServiceSidebarItem title={'Serving'}
            active={selectedTab === 'model-explorer' || selectedTab === 'model-deployments'
              || selectedTab === 'model-deployment-detail' || selectedTab === 'llm-detail'}
            link={mergeUrl(Urls.AI_MODEL_EXPLORER, { projectId: currentProjectId })}
            icon={<ServingIcon />}
            className='ai' />
          <ServiceSidebarSubItem title={'Model explorer'}
            active={selectedTab === 'model-explorer'}
            link={mergeUrl(Urls.AI_MODEL_EXPLORER, { projectId: currentProjectId })} />
          <ServiceSidebarSubItem title={'Model deployments'}
            active={selectedTab === 'model-deployments' || selectedTab === 'model-deployment-detail'
              || selectedTab === 'llm-detail'}
            link={mergeUrl(Urls.AI_MODEL_DEPLOYMENTS, { projectId: currentProjectId })} />

          <ServiceSidebarItem title={'Prototyping'}
            active={selectedTab === 'jupyter-notebook'}
            link={mergeUrl(Urls.AI_JUPYTER_NOTEBOOK, { projectId: currentProjectId })}
            icon={<PrototypingIcon />}
            className='ai' />
          <ServiceSidebarSubItem title={'Jupyter notebook'}
            active={selectedTab === 'jupyter-notebook'}
            link={mergeUrl(Urls.AI_JUPYTER_NOTEBOOK, { projectId: currentProjectId })} />

          <ServiceSidebarItem title={'Training'}
            active={selectedTab === 'gpu-node' || selectedTab === 'gpu-node-detail'
              || selectedTab === 'gpu-node-detail-base'
              || selectedTab === 'storage' || selectedTab === 'storage-detail'}
            link={mergeUrl(Urls.AI_GPU_NODE, { projectId: currentProjectId })}
            icon={<TrainingIcon />}
            className='ai' />

          <ServiceSidebarSubItem title={'GPU node'}
            active={selectedTab === 'gpu-node'
              || selectedTab === 'gpu-node-detail'
              || selectedTab === 'gpu-node-detail-base'}
            link={mergeUrl(Urls.AI_GPU_NODE, { projectId: currentProjectId })} />
          <ServiceSidebarSubItem title={'Persistent Storage'}
            suffixIcon={<BetaIcon />}
            active={selectedTab === 'storage' || selectedTab === 'storage-detail'}
            link={mergeUrl(Urls.AI_STORAGE, { projectId: currentProjectId })} />

          <ServiceSidebarItem title={'Agentic AI'}
            active={selectedTab === 'rag-chatbot' || selectedTab === 'rag-chatbot-detail'}
            link={mergeUrl(Urls.AI_RAG_CHATBOT, { projectId: currentProjectId })}
            icon={<AgenticAiIcon />}
            className='ai' />
          <ServiceSidebarSubItem title={'RAG chatbot'}
            active={selectedTab === 'rag-chatbot' || selectedTab === 'rag-chatbot-detail'}
            suffixIcon={<BetaIcon />}
            link={mergeUrl(Urls.AI_RAG_CHATBOT, { projectId: currentProjectId })} />

        </div>
      )}

      <div className={'ServicePage'}>
        {selectedTab === 'jupyter-notebook' && <JupyterNotebookPage />}
        {selectedTab === 'model-deployments' && <ModelDeploymentsPage />}
        {selectedTab === 'llm-detail' && <LlmDetailPage />}
        {selectedTab === 'model-explorer' && <ModelExplorerPage />}
        {selectedTab === 'model-deployment-detail' && <ModelDeploymentDetailPage />}
        {selectedTab === 'gpu-marketplace' && <GPUMarketingPage />}
        {selectedTab === 'gpu-node' && <GPUNodePage />}
        {selectedTab === 'gpu-node-detail' && <GpuNodeDetailPage />}
        {selectedTab === 'gpu-node-detail-base' && <GpuNodeDetailPage />}
        {selectedTab === 'gpu-node-update' && <GpuNodeUpdatePage />}
        {selectedTab === 'rag-chatbot' && <RAGChatbotPage />}
        {selectedTab === 'rag-chatbot-detail' && <RagChatbotDetailPage />}
        {selectedTab === 'storage' && <AiStorageListPage />}
        {selectedTab === 'storage-detail' && <StorageDetailsPage />}
      </div>
    </>}
    {currentOrg && currentOrg.suspended && <Overlay type={'suspended'} />}
    {currentOrg && currentOrg.disabled && <Overlay type={'disabled'} />}
  </div>
  );
}

export default AiServicesPage;
