export const MOCK_TOOLS = [
  {
    id: "23332439",
    chatbot_id: "chat123",
    name: "Web Search",
    description: "Search the web for information about any topic",
    tool_type: "api",
    enabled: true,
    config: {
      endpoint_template: "https://api.search.com/v1/search/{query}",
      method: "GET",
      headers_template: JSON.stringify({
        "x-api-key": "demo-key",
        "Accept": "application/json"
      }),
      body_template: null
    },
    parameters: {
      type: "object",
      properties: {
        query: {
          type: "string",
          description: "The search query to look up"
        },
        limit: {
          type: "number",
          description: "Maximum number of results to return"
        }
      },
      required: ["query"]
    }
  },
  // Duplicate the first 3 tools with different IDs
  {
    id: "23332447",
    chatbot_id: "chat123",
    name: "Web Search Pro",
    description: "Search the web for information about any topic",
    tool_type: "api",
    enabled: true,
    config: {
      endpoint_template: "https://api.search.com/v1/search/{query}",
      method: "GET",
      headers_template: JSON.stringify({
        "x-api-key": "demo-key",
        "Accept": "application/json"
      }),
      body_template: null
    },
    parameters: {
      type: "object",
      properties: {
        query: {
          type: "string",
          description: "The search query to look up"
        },
        limit: {
          type: "number",
          description: "Maximum number of results to return"
        }
      },
      required: ["query"]
    }
  },
  // ... rest of the existing tools ...
  // Duplicate more tools with incremented IDs
].concat(Array(10).fill(null).map((_, index) => ({
  id: `2333244${index + 7}`,
  chatbot_id: "chat123",
  name: `Tool ${index + 1}`,
  description: "Generic tool description",
  tool_type: "api",
  enabled: index % 2 === 0,
  config: {
    endpoint_template: "https://api.example.com/v1/endpoint",
    method: "POST",
    headers_template: JSON.stringify({
      "Authorization": "Bearer token",
      "Content-Type": "application/json"
    })
  },
  parameters: {
    type: "object",
    properties: {
      param1: {
        type: "string",
        description: "First parameter"
      },
      param2: {
        type: "number",
        description: "Second parameter"
      }
    },
    required: ["param1"]
  }
}))); 