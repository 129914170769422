import React, { useEffect, useState } from "react";
import { DownChevronIcon, DownloadIcon, SendIcon, WarningIcon } from "../Svg";
import { Button } from "../Button";
import _ from "lodash";
import cx from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../Loader";
import { selectMobileLayout } from "../../store/UIState";
import { createSelector } from "reselect";
import { ImageInput } from "../form/ImageInput";
import { AudioInput } from "../form/AudioInput";
import Tooltip from "../Tooltip";
import { AIShowcase } from "../../store/models/AIShowcase";
import { ShowcaseGallery } from "./ShowcaseGallery";
import { AudioGenerator } from "./AudioGenerator";

const LoadingItem = ({ loadingData, elapsedTime }) => {
  if (!loadingData) return <></>

  if (loadingData.msg === 'estimation') {
    return (
      <div className={cx("ResultBox")}>
        <div className={"ResultBox__Loading"}>
          <div className={"ResultBox__Loading__TopWrapper"}>
            Scheduled | {elapsedTime}s / {loadingData.rank_eta?.toFixed(0) ?? '?'}s
          </div>

          <div className={"ResultBox__Loading__CenterWrapper"}>
            <Loader size={"small"} color={"green"} /> Waiting for available slot...
          </div>

          <div className={"ResultBox__Loading__BottomWrapper"}>Number of jobs ahead of you: <span
            style={{ color: '#ffffff' }}>{loadingData.rank + 1}</span></div>
        </div>
      </div>)
  }

  if (loadingData.msg === 'process_starts') {
    return (<div className={cx("ResultBox")}>
      <div className={"ResultBox__Loading"}>
        <div className={"ResultBox__Loading__TopWrapper"}>
          Processing | {elapsedTime}s / {loadingData.eta?.toFixed(0) ?? '?'}s
        </div>

        <div className={"ResultBox__Loading__CenterWrapper"}>
          <Loader color={"green"} />
        </div>

        <div className={"ResultBox__Loading__BottomWrapper"}>Generation in progress...</div>
      </div>
    </div>)
  }
}

const ResultItem = ({ item }) => {
  return (
    <div className={cx("ResultBox")}>
      <div className={"ResultBox__Video"}>
        <video controls src={item.video} />
      </div>

      <div className={"ResultBox__DownloadWrapper"}>
        <Tooltip tooltip={"Download"}>
          <a href={item.video} download target={"_blank"}><DownloadIcon /></a>
        </Tooltip>
      </div>
    </div>
  )
}

const ImageInputInstructions = ({ type }) => {
  return (<>
    <div><span className={"ImageInput__Label--Green"}>Upload {type}</span></div>
    <div>or</div>
    <div>drag {type} here</div>
  </>)
}

const AudioInputInstructions = () => {
  return (<>
    <div><span className={"AudioInput__Label--Green"}>Upload an audio file</span></div>
    <div>or</div>
    <div>drag audio here</div>
  </>)
}

const selector = createSelector(
  selectMobileLayout,
  (isMobile) => ({
    isMobile
  })
)

let intervalId = null;

export const ShowcaseTalkingHead = ({ showcase }) => {
  const dispatch = useDispatch();

  const url = showcase?.models?.[0]?.urls
  const { isMobile } = useSelector(state => selector(state));
  const [videoGenerating, setVideoGenerating] = useState(false)
  const [loadingData, setLoadingData] = useState(null)
  const [error, setError] = useState(null)
  const [elapsedTime, setElapsedTime] = useState(0)
  const [messages, setMessages] = useState([])
  const chatboxRef = React.createRef();

  // Image upload state
  const [currentImage, setCurrentImage] = useState(null)
  const [currentImageFile, setCurrentImageFile] = useState(null)
  const [uploadedImagePath, setUploadedImagePath] = useState(null)

  // Audio upload state
  const [currentAudio, setCurrentAudio] = useState(null)
  const [currentAudioFile, setCurrentAudioFile] = useState(null)
  const [uploadedAudioPath, setUploadedAudioPath] = useState(null)
  const [audioWarning, setAudioWarning] = useState(null)

  const [audioGenerating, setAudioGenerating] = useState(false);

  // Add new state for audio generator visibility
  const [showAudioGenerator, setShowAudioGenerator] = useState(!isMobile);

  useEffect(() => {
    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
      dispatch(AIShowcase.actions.stopTalkingHeadGeneration())
    }
  }, []);

  const onGenerateVideo = async (event) => {
    setError(null)
    event.preventDefault();

    if (!uploadedImagePath || !uploadedAudioPath) {
      setError("Please upload both a portrait image and an audio file")
      return
    }

    // Clear any warnings when generating
    setAudioWarning(null)

    setVideoGenerating(true)
    setElapsedTime(0)

    if (intervalId) {
      clearInterval(intervalId)
    }

    intervalId = setInterval(() => {
      setElapsedTime(prevElapsedTime => prevElapsedTime + 1)
    }, 1000)

    setMessages([{ type: 'loading' }, ...messages])

    if (isMobile) {
      setTimeout(() => {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: 'smooth'
        });
      }, 1000);
    }

    try {
      await dispatch(AIShowcase.actions.talkingHeadPredict(url, {
        imagePath: uploadedImagePath,
        imageName: currentImageFile?.name || "portrait.jpg",
        audioPath: uploadedAudioPath,
        audioName: currentAudioFile?.name || "audio.mp3"
      }, (data) => {
        if (data.msg === 'error') {
          setError(data.error)
          setVideoGenerating(false)
          setLoadingData(null)
          if (intervalId) {
            clearInterval(intervalId)
          }
          return
        }

        if (data.msg === 'process_starts') {
          setMessages([{ type: 'loading' }, ...messages])
        }


        if (data.msg === 'process_completed') {
          if (data.success) {
            const videoUrl = data.output.data[0].value.video.url;
            setMessages([{ type: 'result', video: videoUrl }, ...messages])
          }

          setVideoGenerating(false)
          setLoadingData(null)

          if (intervalId) {
            clearInterval(intervalId)
          }
          return
        }

        setLoadingData(data)
      }))
    } catch (e) {
      console.error(e)
      setError("Failed to generate the talking head video. Please try again later.")
      setVideoGenerating(false)
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }

  const onStopGenerating = (e) => {
    e.preventDefault()
    dispatch(AIShowcase.actions.stopTalkingHeadGeneration())
    setVideoGenerating(false)
    setLoadingData(null)

    // Remove the loading message from messages array
    setMessages(prevMessages => prevMessages.filter(msg => msg.type !== 'loading'))

    if (intervalId) {
      clearInterval(intervalId)
    }
  }

  const onImageSelected = async (file) => {
    if (!file) return

    try {
      setCurrentImageFile(file)
      setCurrentImage(URL.createObjectURL(file))

      const formData = new FormData()
      formData.append('files', file)

      const uploadId = Math.random().toString(36).substring(2, 10)
      const response = await fetch(`${url}/upload?upload_id=${uploadId}`, {
        method: 'POST',
        body: formData
      })

      if (!response.ok) {
        throw new Error('Failed to upload image')
      }

      const data = await response.json()
      setUploadedImagePath(data[0])

    } catch (error) {
      console.error('Upload error:', error)
      toast.error('Failed to upload file. Please try again.')
    }
  }

  const onAudioSelected = async (file) => {
    if (!file) return

    try {
      setCurrentAudioFile(file)
      setCurrentAudio(URL.createObjectURL(file))
      
      // Check audio duration
      const audio = new Audio();
      audio.src = URL.createObjectURL(file);
      
      audio.addEventListener('loadedmetadata', () => {
        if (audio.duration >= 15) {
          setAudioWarning("Longer clips may cause extended processing times or timeouts. For best results, consider using shorter audio (under 15 seconds).");
        } else {
          setAudioWarning(null);
        }
      });

      const formData = new FormData()
      formData.append('files', file)

      const uploadId = Math.random().toString(36).substring(2, 10)
      const response = await fetch(`${url}/upload?upload_id=${uploadId}`, {
        method: 'POST',
        body: formData
      })

      if (!response.ok) {
        throw new Error('Failed to upload audio')
      }

      const data = await response.json()
      setUploadedAudioPath(data[0])
    } catch (error) {
      console.error('Upload error:', error)
      toast.error('Failed to upload audio file. Please try again.')
    }
  }

  const onRemoveImage = () => {
    setCurrentImage(null)
    setCurrentImageFile(null)
    setUploadedImagePath(null)
  }

  const onRemoveAudio = () => {
    setCurrentAudio(null)
    setCurrentAudioFile(null)
    setUploadedAudioPath(null)
    setAudioWarning(null)
  }

  const onRemoveImages = (event) => {
    event.preventDefault()
    onRemoveImage()
    onRemoveAudio()
  }

  const onSelectSample = async (index, name) => {
    try {
      if (name === 'portrait') {
        const portraitUrl = existingPortraits[index];

        const response = await fetch(portraitUrl);
        if (!response.ok) {
          throw new Error('Failed to fetch portrait image');
        }

        const blob = await response.blob();

        const fileName = portraitUrl.split('/').pop();
        const file = new File([blob], fileName, { type: 'image/jpeg' });

        onImageSelected(file);
      } else if (name === 'audio') {
        const audioItem = existingAudios[index];

        // Fetch the audio file content
        const response = await fetch(audioItem.url);
        if (!response.ok) {
          throw new Error('Failed to fetch audio file');
        }

        // Create a blob from the response
        const blob = await response.blob();

        // Create a File object from the blob
        const fileName = audioItem.label || audioItem.url.split('/').pop();
        const file = new File([blob], fileName, { type: 'audio/mpeg' });

        onAudioSelected(file);
      }
    } catch (error) {
      console.error('Error selecting gallery item:', error);
      toast.error('Failed to select example. Please try again.');
    }
  }

  const onAudioGenerated = async (audioUrl) => {
    try {
      // Create a synthetic file object
      const fileName = "generated_audio.mp3";

      const response = await fetch(audioUrl);
      if (!response.ok) {
        throw new Error('Failed to fetch audio file');
      }

      // Create a blob from the response
      const blob = await response.blob();

      // Create a File object from the blob
      const file = new File([blob], fileName, { type: 'audio/mpeg' });

      onAudioSelected(file);
    } catch (error) {
      console.error('Error with generated audio:', error);
      toast.error('Failed to process generated audio. Please try again.');
    }
  };

  const onStopAudioGenerating = (e) => {
    if (e) e.preventDefault();
    setAudioGenerating(false);

    // Dispatch action to stop audio generation if needed
    dispatch(AIShowcase.actions.stopAudioGeneration());

    // If there's any audio-related loading message, we could clean it up here
    // This is only needed if you're showing audio generation progress in the messages
  }

  const onToggleAudioGenerator = () => {
    setShowAudioGenerator(!showAudioGenerator);
  }

  return (
    <div className="ShowcaseTalkingHead">
      <div className="ShowcaseTalkingHead__container">
        <div className={"ShowcaseTalkingHead__LeftSide"}>
          <div className={"SidePanel"}>
            <form className="SidePanel__form">
              <div className={"SidePanel__ImageInputs"}>
                <ImageInput
                  value={currentImage}
                  name={"image"}
                  label={"Portrait Image"}
                  onChange={onImageSelected}
                  onRemove={onRemoveImage}
                  formats={"image/jpeg, image/jpg, image/png, image/webp"}
                  instructions={<ImageInputInstructions type={"portrait image"} />}
                >
                  <div className={"ImageInput__LeftBadge"}>Portrait</div>
                </ImageInput>

                {isMobile && (
                  <ShowcaseGallery
                    images={existingPortraits}
                    title={"Portrait examples"}
                    onClick={onSelectSample}
                    name={"portrait"}
                  />
                )}

                {audioGenerating ? (
                  <div className="AudioInput AudioInput__Loading">
                    <div className="AudioInput__Loading__Content">
                      <Loader position="none" color={"green"} />
                      <div className="AudioInput__Loading__Text">Generating audio...</div>
                    </div>
                  </div>
                ) : (
                  <AudioInput
                    value={currentAudio}
                    name={"audio"}
                    onChange={onAudioSelected}
                    onRemove={onRemoveAudio}
                    formats={"audio/mpeg, audio/mp3, audio/wav"}
                    instructions={<AudioInputInstructions />}
                    displayName={currentAudioFile?.name}
                  >
                    <div className={"ImageInput__LeftBadge"}>Audio</div>
                  </AudioInput>
                )}

                {isMobile && (
                  <ShowcaseGallery
                    entries={existingAudios}
                    title={"Audio examples"}
                    onClick={onSelectSample}
                    name={"audio"}
                  />
                )}
              </div>

              <div className={"SidePanel__Examples"}>
                {!isMobile && (
                  <ShowcaseGallery
                    images={existingPortraits}
                    title={"Portrait examples"}
                    onClick={onSelectSample}
                    name={"portrait"}
                  />
                )}
                {!isMobile && (
                  <ShowcaseGallery
                    entries={existingAudios}
                    title={"Audio examples"}
                    onClick={onSelectSample}
                    name={"audio"}
                  />
                )}
              </div>

              <div className={"SidePanel__ActionButtons"}>
                <Button
                  title={"Clear"}
                  color={"grey"}
                  className="SidePanel__ActionButtons__Button SidePanel__ActionButtons__Button"
                  disabled={_.isNil(currentImage) && _.isNil(currentAudio)}
                  onClick={onRemoveImages}
                />

                {loadingData
                  ? <Button
                    title={"Stop generating"}
                    onClick={onStopGenerating}
                    className="SidePanel__ActionButtons__Button--Primary"
                    color={"red"}
                  />
                  : <Button
                    title={"Generate Video"}
                    onClick={onGenerateVideo}
                    loading={videoGenerating}
                    icon={videoGenerating ? null : <SendIcon />}
                    className="SidePanel__ActionButtons__Button SidePanel__ActionButtons__Button--Primary"
                    color={"green"}
                    disabled={!_.isNil(loadingData) || _.isNil(uploadedImagePath) || _.isNil(uploadedAudioPath) || audioWarning} />}
              </div>

              {audioWarning && (
                <div className="AudioWarning">
                  {audioWarning}
                </div>
              )}

              <div className={`SidePanel__OutputOptions ${showAudioGenerator && "open"}`}
                onClick={onToggleAudioGenerator}>Generate audio <DownChevronIcon /></div>

              {showAudioGenerator && (
                <div className="SidePanel__AudioGenerator">
                  <AudioGenerator
                    onAudioGenerated={onAudioGenerated}
                    isGenerating={audioGenerating}
                    setIsGenerating={setAudioGenerating}
                    url={url}
                    onStopGenerating={onStopAudioGenerating}
                  />
                </div>
              )}
            </form>
          </div>
        </div>

        <div className={cx("ShowcaseTalkingHead__RightSide")}>
          {error && <div className={"ErrorMessage"}>{error}</div>}

          <div className={"MessagesWrapper"} ref={chatboxRef}>
            {!_.isEmpty(messages) && messages.map((item, index) => {
              switch (item.type) {
                case 'loading':
                  return (<LoadingItem key={index} loadingData={loadingData} elapsedTime={elapsedTime} />)
                case 'result':
                  return (<ResultItem key={index} item={item} />)
                default:
                  return null;
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

const existingPortraits = [
  `/images/showcase/talking-head/david.jpg`,
  `/images/showcase/talking-head/einstein.jpg`,
  `/images/showcase/talking-head/gates.jpg`,
  `/images/showcase/talking-head/monalisa.jpg`,
  `/images/showcase/talking-head/churchill.jpg`,
]

const existingAudios = [
  {
    url: `/audio/hope.mp3`,
    label: "hope.mp3"
  },
  {
    url: `/audio/apartment.mp3`,
    label: "apartment.mp3"
  },
  {
    url: `/audio/listen.mp3`,
    label: "listen.mp3"
  },
  {
    url: `/audio/berliner.mp3`,
    label: "berliner.mp3"
  },
  {
    url: `/audio/dream.mp3`,
    label: "dream.mp3"
  }
] 