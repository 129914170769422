import React, { useState, useRef, useEffect } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from "react-redux";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../components/Modal";
import { Button } from "../components/Button";
import { SelectInput } from '../components/form/SelectInput';
import { QuestionMarkIcon, CloseIcon, RoundCloseIcon } from '../components/Svg';
import Tooltip from '../components/Tooltip';
import ChatbotTools from '../store/models/RagChatbot/ChatbotTools';
import { toast } from "react-toastify";
import { Toggle } from '../components/form/Toggle';
import { AiTabs, AiTab } from '../components/AiTabs';
import { selectCurrentProjectId } from '../store/models/Project';


const customComponents = {
  IndicatorSeparator: () => null,
  CheckIcon: () => null
};

const methodOptions = [
  { value: 'GET', label: 'GET' },
  { value: 'POST', label: 'POST' },
  { value: 'PUT', label: 'PUT' },
  { value: 'DELETE', label: 'DELETE' }
];

const parameterTypes = [
  { value: 'string', label: 'Text' },
  { value: 'number', label: 'Number' },
  { value: 'boolean', label: 'Boolean' }
];

export const CreateChatbotToolModal = ({ onRequestClose, chatbotId, tool }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [name, setName] = useState(tool?.name || '');
  const [description, setDescription] = useState(tool?.description || '');
  const [endpoint, setEndpoint] = useState(tool?.config?.endpoint_template || '');
  const [method, setMethod] = useState(() => {
    if (tool?.config?.method) {
      return methodOptions.find(m => m.value === tool.config.method) || methodOptions[0];
    }
    return methodOptions[0];
  });
  const [enabled, setEnabled] = useState(tool?.enabled ?? true);
  const [parameters, setParameters] = useState(() => {
    if (tool?.parameters?.properties) {
      return Object.entries(tool.parameters.properties).map(([name, prop]) => ({
        name,
        type: prop.type.toLowerCase(),
        description: prop.description
      }));
    }
    return [{ name: '', type: 'string', description: '' }];
  });
  const [headers, setHeaders] = useState(() => {
    if (tool?.config?.headers) {
      try {
        return Object.entries(tool.config.headers).map(([key, value]) => ({ key, value }));
      } catch (e) {
        console.error('Failed to parse headers template:', e);
        return [{ key: '', value: '' }];
      }
    }
    return [{ key: '', value: '' }];
  });
  const [bodyKeyValues, setBodyKeyValues] = useState(() => {
    if (tool?.config?.body_template) {
      try {
        const parsedBodyTemplate = typeof tool.config.body_template === 'string' 
          ? JSON.parse(tool.config.body_template) 
          : tool.config.body_template;
          
        return Object.entries(parsedBodyTemplate).map(([key, value]) => ({ 
          key, 
          value: typeof value === 'string' ? value : JSON.stringify(value)
        }));
      } catch (e) {
        console.error('Failed to parse body template:', e);
        return [{ key: '', value: '' }];
      }
    }
    return [{ key: '', value: '' }];
  });
  const [activeTab, setActiveTab] = useState('headers');
  const [bulkEdit, setBulkEdit] = useState(false);
  const [headersBulkText, setHeadersBulkText] = useState('');
  const [bodyBulkText, setBodyBulkText] = useState('');
  
  const dispatch = useDispatch();
  const projectId = useSelector(state => selectCurrentProjectId(state));

  const onAddParameter = () => {
    setParameters([...parameters, { name: '', type: 'TEXT', description: '' }]);
  };

  const onRemoveParameter = (index) => {
    setParameters(parameters.filter((_, i) => i !== index));
  };

  const onUpdateParameter = (index, field, value) => {
    const newParameters = [...parameters];
    newParameters[index] = { ...newParameters[index], [field]: value };
    setParameters(newParameters);
  };

  const onAddHeader = () => {
    setHeaders([...headers, { key: '', value: '' }]);
  };

  const onRemoveHeader = (index) => {
    setHeaders(headers.filter((_, i) => i !== index));
  };

  const onUpdateHeader = (index, field, value) => {
    const newHeaders = [...headers];
    newHeaders[index] = { ...newHeaders[index], [field]: value };
    setHeaders(newHeaders);
  };

  const onAddBodyKeyValue = () => {
    setBodyKeyValues([...bodyKeyValues, { key: '', value: '' }]);
  };

  const onRemoveBodyKeyValue = (index) => {
    setBodyKeyValues(bodyKeyValues.filter((_, i) => i !== index));
  };

  const onUpdateBodyKeyValue = (index, field, value) => {
    const newBodyKeyValues = [...bodyKeyValues];
    newBodyKeyValues[index] = { ...newBodyKeyValues[index], [field]: value };
    setBodyKeyValues(newBodyKeyValues);
  };

  const onSubmit = async () => {
    if (!name || !description || !endpoint) {
      setError("Please fill in all required fields.");
      return;
    }

    const validParameters = parameters.filter(p => p.name && p.description);

    setLoading(true);
    setError(null);

    try {
      const headersTemplate = headers.reduce((acc, { key, value }) => {
        if (key && value) {
          acc[key] = value;
        }
        return acc;
      }, {});

      const bodyTemplate = bodyKeyValues.reduce((acc, { key, value }) => {
        if (key && value) {
          try {
            acc[key] = JSON.parse(value);
          } catch {
            acc[key] = value;
          }
        }
        return acc;
      }, {});

      const toolConfig = {
        project_id: projectId,
        chatbot_id: chatbotId,
        name,
        description,
        tool_type: "api",
        enabled,
        config: {
          endpoint_template: endpoint,
          method: method.value,
          headers: Object.keys(headersTemplate).length > 0 ? headersTemplate : undefined,
          body_template: Object.keys(bodyTemplate).length > 0 ? JSON.stringify(bodyTemplate) : undefined
        },
        parameters: {
          type: "object",
          properties: validParameters.reduce((acc, param) => ({
            ...acc,
            [param.name]: {
              type: param.type,
              description: param.description
            }
          }), {}),
          required: validParameters.map(p => p.name)
        }
      };

      if (tool?.id) {
        await dispatch(ChatbotTools.actions.updateChatbotTool(chatbotId, tool.id, toolConfig));
        toast.success("Tool updated successfully");
      } else {
        await dispatch(ChatbotTools.actions.createChatbotTool(chatbotId, toolConfig));
        toast.success("Tool created successfully");
      }
      onRequestClose();
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const convertToJson = () => {
    if (activeTab === 'headers') {
      const data = headers.reduce((acc, { key, value }) => {
        if (key && value) acc[key] = value;
        return acc;
      }, {});
      setHeadersBulkText(JSON.stringify(data, null, 2));
    } else {
      const data = bodyKeyValues.reduce((acc, { key, value }) => {
        if (key && value) {
          try {
            acc[key] = JSON.parse(value);
          } catch {
            acc[key] = value;
          }
        }
        return acc;
      }, {});
      setBodyBulkText(JSON.stringify(data, null, 2));
    }
    setBulkEdit(true);
  };

  const applyBulkEdit = () => {
    try {
      const bulkText = activeTab === 'headers' ? headersBulkText : bodyBulkText;
      const parsed = JSON.parse(bulkText);
      const keyValues = Object.entries(parsed).map(([key, value]) => ({
        key,
        value: typeof value === 'object' ? JSON.stringify(value) : String(value)
      }));

      if (activeTab === 'headers') {
        setHeaders(keyValues);
      } else {
        setBodyKeyValues(keyValues);
      }
      setBulkEdit(false);
    } catch (e) {
      setError("Invalid JSON format");
    }
  };

  return (
    <Modal className="CreateChatbotToolModal" onRequestClose={onRequestClose}>
      <ModalHeader>
        <ModalTitle align="row">
          {tool?.id ? 'Edit Custom Tool' : 'Create New Custom Tool'}
          <Tooltip tooltip="Open documentation">
            <a href="https://docs.thetatoken.org/docs/edgecloud-rag-chatbot-agentic-tools" target="_blank" rel="noopener noreferrer">
              <QuestionMarkIcon />
            </a>
          </Tooltip>
        </ModalTitle>
      </ModalHeader>

      <ModalContent>
        <div className="CreateChatbotToolModal__row">
          <div className="CreateChatbotToolModal__select-wrap name">
            <div className="CreateChatbotToolModal__select-label white">Tool Name</div>
            <input
              className="CreateChatbotToolModal__input"
              placeholder="Enter tool name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="CreateChatbotToolModal__select-wrap short name toggle">
            <div className="CreateChatbotToolModal__select-label">Enabled</div>
            <Toggle
              checked={enabled}
              onChange={(e) => setEnabled(e.target.checked)}
            />
          </div>
        </div>

        <div className="CreateChatbotToolModal__row">
          <div className="CreateChatbotToolModal__select-wrap">
            <div className="CreateChatbotToolModal__select-label white">When to use</div>
            <textarea
              className="CreateChatbotToolModal__textarea"
              placeholder="Describe when the chatbot should use this tool in natural language"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </div>

        <div className="CreateChatbotToolModal__row">
          <div className="CreateChatbotToolModal__select-wrap method">
            <div className="CreateChatbotToolModal__select-label">Method</div>
            <SelectInput
              options={methodOptions}
              value={method}
              onChange={setMethod}
              styles={selectStyles}
              components={customComponents}
            />
          </div>

          <div className="CreateChatbotToolModal__select-wrap endpoint">
            <div className="CreateChatbotToolModal__select-label">API Endpoint</div>
            <input
              className="CreateChatbotToolModal__input"
              placeholder="https://api.example.com/endpoint/{param}"
              value={endpoint}
              onChange={(e) => setEndpoint(e.target.value)}
            />
          </div>
        </div>

        <div className="CreateChatbotToolModal__tabs-container">
          <AiTabs>
            <AiTab 
              isSelected={activeTab === 'headers'}
              onClick={() => {
                if (!bulkEdit) {
                  setActiveTab('headers');
                } else {
                  toast.warning("Please apply or cancel your bulk edit before switching tabs");
                }
              }}>
              Headers
            </AiTab>
            <AiTab 
              isSelected={activeTab === 'body'}
              onClick={() => {
                if (!bulkEdit) {
                  setActiveTab('body');
                } else {
                  toast.warning("Please apply or cancel your bulk edit before switching tabs");
                }
              }}>
              Body
            </AiTab>
          </AiTabs>

          {activeTab === 'headers' && (
            <div className="CreateChatbotToolModal__tab-content">
              {bulkEdit ? (
                <>
                  
                  <textarea
                    className="CreateChatbotToolModal__textarea sub"
                    value={headersBulkText}
                    onChange={(e) => setHeadersBulkText(e.target.value)}
                    placeholder="Enter headers in JSON format"
                  />
                  <div className="CreateChatbotToolModal__actions subs">
                    <Button
                      color="transparent"
                      className="CreateChatbotToolModal__sub-button"
                      onClick={applyBulkEdit}
                      title="Apply"
                    />
                    <Button
                      color="transparent"
                      className="CreateChatbotToolModal__sub-button"
                      onClick={() => setBulkEdit(false)}
                      title="Cancel"
                    />
                  </div>
                </>
              ) : (
                <>
                  {headers.map((header, index) => (
                    <div key={index} className="CreateChatbotToolModal__row">
                      <div className="CreateChatbotToolModal__select-wrap key">
                        <input
                          className="CreateChatbotToolModal__input sub"
                          placeholder="Key"
                          value={header.key}
                          onChange={(e) => onUpdateHeader(index, 'key', e.target.value)}
                        />
                      </div>
                      <div className="CreateChatbotToolModal__select-wrap value">
                        <input
                          className="CreateChatbotToolModal__input sub"
                          placeholder="Value"
                          value={header.value}
                          onChange={(e) => onUpdateHeader(index, 'value', e.target.value)}
                        />
                      </div>
                      <div 
                        className={cx("CreateChatbotToolModal__remove-button")}
                        onClick={() => onRemoveHeader(index)}
                      >
                        <RoundCloseIcon />
                      </div>
                    </div>
                  ))}
                  <div className="CreateChatbotToolModal__actions">
                    <Button
                      color="transparent"
                      className="CreateChatbotToolModal__add-parameter"
                      onClick={onAddHeader}
                      title="+ Add Header"
                    />
                    <Button
                      color="transparent"
                      onClick={convertToJson}
                      title="Bulk edit"
                    />
                  </div>
                </>
              )}
            </div>
          )}

          {activeTab === 'body' && (
            <div className="CreateChatbotToolModal__tab-content">
              {bulkEdit ? (
                <>
                  <textarea
                    className="CreateChatbotToolModal__textarea sub"
                    value={bodyBulkText}
                    onChange={(e) => setBodyBulkText(e.target.value)}
                    placeholder="Enter body in JSON format"
                  />
                  <div className="CreateChatbotToolModal__actions subs">
                    <Button
                      color="transparent"
                      className="CreateChatbotToolModal__sub-button"
                      onClick={applyBulkEdit}
                      title="Apply"
                    />
                    <Button
                      color="transparent"
                      className="CreateChatbotToolModal__sub-button"
                      onClick={() => setBulkEdit(false)}
                      title="Cancel"
                    />
                  </div>
                </>
              ) : (
                <>
                  {bodyKeyValues.map((item, index) => (
                    <div key={index} className="CreateChatbotToolModal__row">
                      <div className="CreateChatbotToolModal__select-wrap key">
                        <input
                          className="CreateChatbotToolModal__input sub"
                          placeholder="Key"
                          value={item.key}
                          onChange={(e) => onUpdateBodyKeyValue(index, 'key', e.target.value)}
                        />
                      </div>
                      <div className="CreateChatbotToolModal__select-wrap value">
                        <input
                          className="CreateChatbotToolModal__input sub"
                          placeholder="Value"
                          value={item.value}
                          onChange={(e) => onUpdateBodyKeyValue(index, 'value', e.target.value)}
                        />
                      </div>
                      <div 
                        className={cx("CreateChatbotToolModal__remove-button")}
                        onClick={() => onRemoveBodyKeyValue(index)}
                      >
                        <RoundCloseIcon />
                      </div>
                    </div>
                  ))}

                  <div className="CreateChatbotToolModal__actions">
                    <Button
                      color="transparent"
                      className="CreateChatbotToolModal__add-parameter"
                      onClick={onAddBodyKeyValue}
                      title="+ Add Body Field"
                    />
                    <Button
                      color="transparent"
                      onClick={convertToJson}
                      title="Bulk edit"
                    />
                  </div>
                </>
              )}
            </div>
          )}
        </div>

        <div className="CreateChatbotToolModal__select-label white" style={{ marginTop: 20 }}>
          Variables
        </div>
        <div className="CreateChatbotToolModal__tab-content">
          {parameters.map((param, index) => (
            <div key={index} className="CreateChatbotToolModal__row CreateChatbotToolModal__row--parameters">
              <div className="CreateChatbotToolModal__select-wrap parameter-name">
                <div className="CreateChatbotToolModal__select-label">
                  Name
                </div>
                <input
                  className="CreateChatbotToolModal__input sub"
                  placeholder="Parameter name"
                  value={param.name}
                  onChange={(e) => onUpdateParameter(index, 'name', e.target.value)}
                />
              </div>
              <div className="CreateChatbotToolModal__select-wrap parameter-type">
                <div className="CreateChatbotToolModal__select-label">Type</div>
                <SelectInput
                  options={parameterTypes}
                  value={parameterTypes.find(t => t.value === param.type)}
                  onChange={(value) => onUpdateParameter(index, 'type', value.value)}
                  styles={selectStyles}
                  isSub={true}
                  components={customComponents}
                />
              </div>
              <div className="CreateChatbotToolModal__select-wrap parameter-desc">
                <div className="CreateChatbotToolModal__select-label">
                  Description
                </div>
                <input
                  className="CreateChatbotToolModal__input sub"
                  placeholder="Parameter description"
                  value={param.description}
                  onChange={(e) => onUpdateParameter(index, 'description', e.target.value)}
                />

              </div>


              <div 
                className={cx("CreateChatbotToolModal__remove-button align-bottom")}
                onClick={() => onRemoveParameter(index)}
              >
                <RoundCloseIcon />
              </div>
            </div>
          ))}

          <Button
            color="transparent"
            onClick={onAddParameter}
            className="CreateChatbotToolModal__add-parameter"
            title="+ Add Parameter"
            style={{ marginTop: '10px' }}
          />
        </div>

        {error && <div className={cx("CreateChatbotToolModal__error")}>{error}</div>}
      </ModalContent>

      <ModalActions>
        <Button 
          color="green" 
          onClick={onSubmit} 
          title={tool?.id ? "Update Tool" : "Create Tool"} 
          loading={loading} 
        />
        <Button color="transparent" onClick={onRequestClose} title="Cancel" />
      </ModalActions>
    </Modal>
  );
}; 


const selectStyles = {
  container: (styles, { isDisabled }) => ({
    ...styles,
    flex: 1,
    height: 40,
    cursor: isDisabled ? 'not-allowed' : 'default',
  }),
  control: (styles, state) => ({
    ...styles,
    paddingLeft: 15,
    backgroundColor: state.selectProps.isSub ? '#222838' : '#191D29',
    borderColor: '#3D4463',
    borderRadius: 6,
    ':hover': {
      borderColor: 'white',
    },
    '.selected': {
      borderColor: 'white',
    },
  }),
  menu: (styles) => ({
    ...styles,
    border: '1px solid #3D4463',
    backgroundColor: '#191D29',
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: '#191D29',
    borderColor: '#3D4463',
    color: isDisabled ? '#636B91' : (isFocused ? 'white' : (isSelected ? 'white' : '#636B91')),
    opacity: isDisabled ? 0.5 : 1,
  }),
  input: styles => ({ ...styles, color: "#fff" }),
  placeholder: styles => ({ ...styles, color: '#636B91' }),
  singleValue: styles => ({ ...styles, color: '#fff', fontWeight: '500' }),
  indicatorSeparator: () => ({ display: 'none' }),
};