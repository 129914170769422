import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useFieldArray, useForm } from "react-hook-form";
import { BackButton, Button } from "../../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import Dapp, { selectDapp, selectDappVideoAPIAccount, selectServiceAccount } from "../../../store/models/DApp";
import Stream from "../../../store/models/Stream";
import { useParams } from "react-router";
import PageTitleBar from "../../../components/PageTitleBar";
import Select from 'react-select'
import { SupportedNetworks, ModalTypes } from '../../../constants';
import cx from "classnames";
import Tooltip from "../../../components/Tooltip";
import EnterpriseBadge from "../../../assets/icons/badge-enterprise.svg";
import { CgClose } from "react-icons/cg";
import { CustomOption } from "./CreateVideo";
import { selectCurrentProject, selectProject } from "../../../store/models/Project";
import { selectCurrentUser, selectCurrentUserId } from '../../../store/models/User';
import UIState from '../../../store/UIState';
import { getStreamErrorMsg } from '../../../utils';

function CreateStreamForm({ onSubmit, loading, isEnterpriseAccount }) {
  const formMethods = useForm({
    defaultValues: {
      use_studio_drm: false,
      drm_rules: [{ nft_collection: "", chain_id: 361 }]
    }
  });
  const { register, handleSubmit, setValue, watch, control, formState: { errors } } = formMethods;
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "drm_rules", // unique name for your Field Array
  });

  const [rerenderFlag, setRerenderFlag] = useState(false);
  const [hasNftDrm, setHasNftDrm] = useState(false);
  const useStudioDrm = formMethods.getValues().use_studio_drm;

  const onChangeValue = (name, value) => {
    setValue(name, value);
    setRerenderFlag(prevFlag => !prevFlag);
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}
        className={'Form CreateVideoForm'}
      >
        <div className={'CreateVideoForm__label'}>Stream name</div>
        <input className='CreateVideoForm__default-input' type="text"
          placeholder="Enter stream name" {...register("name", { required: false })} />

        <div className={'CreateVideoForm__drm'}>
          <div className={'CreateVideoForm__drm--enable-drm'}>
            <div className={'CreateVideoForm__drm--enable-drm--toggle-wrapper'}>
              <input className={"CreateVideoForm__drm--enable-drm--toggle"} type="checkbox" readOnly
                checked={hasNftDrm} />
              <div className={"CreateVideoForm__drm--enable-drm--slider"} onClick={() => setHasNftDrm(!hasNftDrm)} />

            </div>
            <div className={'CreateVideoForm__drm--enable-drm--texts'}>
              <div className={'CreateVideoForm__drm--enable-drm--title'}>Enable NFT based DRM</div>
              <div className={'CreateVideoForm__drm--enable-drm--description'}>{hasNftDrm
                ? "Select the NFTs that will allow access to your stream"
                : "Protect your streams by requiring users to have specific NFT(s)"}
              </div>
            </div>
          </div>

          <div className={cx("drm-details", { hidden: !hasNftDrm })}>
            <input type="hidden" {...register('video_type')} />
            <div className='CreateVideoForm__use-studio-drm'>
              <div className={cx('CreateVideoForm__use-studio-drm--option left', { active: !useStudioDrm })}
                onClick={() => onChangeValue('use_studio_drm', false)}>
                <div className={"CreateVideoForm__use-studio-drm--option--title"}>Standard DRM</div>
                <div className={"CreateVideoForm__use-studio-drm--option--desc"}>Essential protection for digital
                  content,<br />perfect for creators and small businesses.
                </div>
              </div>
              <Tooltip tooltip={"Enterprise Plan Required"} hidden={isEnterpriseAccount}>
                <div className={cx('CreateVideoForm__use-studio-drm--option right', {
                  active: useStudioDrm && isEnterpriseAccount,
                  disabled: !isEnterpriseAccount
                })}
                  onClick={() => isEnterpriseAccount && onChangeValue('use_studio_drm', true)}>
                  <div className={"CreateVideoForm__use-studio-drm--option--badge"}><img src={EnterpriseBadge}
                    alt={"enterprise-badge"} />
                  </div>
                  <div className={"CreateVideoForm__use-studio-drm--option--title"}>Studio-Level DRM</div>
                  <div className={"CreateVideoForm__use-studio-drm--option--desc"}>Advanced security for high-value
                    content<br />like movies and TV.
                  </div>
                </div>
              </Tooltip>
            </div>

            <div className={'CreateVideoForm__nft-collection-title'}>NFT Collections</div>
            <div className={'CreateVideoForm__nft-collection-desc'}>Select the NFT collections that will allow access to
              your stream
            </div>

            {fields.map((field, index) => {
              return <section key={field.id} className="CreateVideoForm__section">
                <div className='CreateVideoForm__drm-left'>
                  <div className='CreateVideoForm__select--wrap'>
                    <Controller
                      name={`drm_rules.${index}.chain_id`}
                      control={control}
                      render={({ field: { onChange } }) => (
                        <Select
                          className='CreateVideoForm__select--resolution'
                          defaultValue={SupportedNetworks[0]}
                          onChange={(option) => onChange(option.value)}
                          styles={selectStyles}
                          options={SupportedNetworks}
                          getOptionLabel={(option) => <CustomOption data={option} />}
                          placeholder={'Select NFT network'}
                          isSearchable={false}
                        />
                      )}
                    />
                  </div>

                </div>
                <div className='CreateVideoForm__drm-address'>
                  <input type="text"
                    className='CreateVideoForm__drm-address--input '
                    placeholder="Collection Address"
                    {...register(`drm_rules.${index}.nft_collection`, { required: false })} />

                  <CgClose className='CreateVideoForm__drm-address--close-btn' onClick={() => remove(index)}
                    color='grey' />
                </div>
              </section>
            })}
            <Button role={'button'}
              className={"CreateVideoForm__nft-collection--add"}
              onClick={() => append({ nft_collection: "", chain_id: 361 })}
              title='Add another collection' />
          </div>
        </div>

        <Button title={'Save'}
          role={'submit'}
          loading={loading}
          style={{
            marginTop: 30,
            width: 200,
            height: 40,
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      </form>
    </FormProvider>
  );
}

const CreateStreamPage = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(null);
  const [warning, setWarning] = useState(null);
  const [error, setError] = useState(null);
  const project = useSelector(state => selectCurrentProject(state));
  const videoApiId = project?.tva_id
  const userId = useSelector(state => selectCurrentUserId(state));
  const curUser = useSelector(state => selectCurrentUser(state));
  const serviceAccount = useSelector(state => selectServiceAccount(state, videoApiId));
  const isEnterpriseAccount = serviceAccount?.max_studio_drm_streams > 0;

  useEffect(() => {
    if (!_.isNil(project) && !_.isNil(userId))
      dispatch(Dapp.actions.fetchServiceAccount())
  }, [project]);

  const onSubmit = async (data) => {
    // let videoData = data;

    if (_.isNil(userId)) {
      dispatch(UIState.actions.showModal(ModalTypes.SIGNUP));
      return;
    }

    setLoading(true);
    setError(null);

    try {
      setState('Creating stream...');
      setWarning(null);

      const streamData = _.pick(data, ['name', 'use_studio_drm'])

      if (data.drm_rules) {
        let collections = data.drm_rules
          .filter(obj => obj.nft_collection !== '')
          .map(obj => ({
            nft_collection: obj.nft_collection.trim(),
            chain_id: parseInt(obj.chain_id) || 361
          }))
        if (collections.length > 0) {
          streamData['drm_rules'] = collections;
          streamData['use_drm'] = true;
        }
      }

      streamData['instance_specs_id'] = "wsdi_spec_rf67m76ke3uh755vhq60t6"

      const stream = await dispatch(Stream.actions.createStream(streamData));
    } catch (e) {
      setLoading(false);
      setState(null);
      setError(e.message || 'Ooops');
    }
  };

  return (
    <div className={'CreateVideoPage'}>
      <div className={'CreateVideoPage__content'}>
        <PageTitleBar title={'New Stream'}
          leftContent={<BackButton title={'Streams'}
            href={`/dashboard/video/livestreams`} />}
        />
        <CreateStreamForm onSubmit={onSubmit}
          loading={loading}
          isEnterpriseAccount={isEnterpriseAccount}
        />
        {
          state &&
          <div className={'CreateVideoPage__state'}>
            {state}
          </div>
        }
        {
          warning &&
          <div className={'CreateVideoPage__warning'}>
            {warning}
          </div>
        }
        {
          error &&
          <div className={'CreateVideoPage__error'}>
            {getStreamErrorMsg(error, curUser)}
          </div>
        }
      </div>
    </div>
  );
}

const selectStyles = {
  control: styles => ({
    ...styles,
    backgroundColor: 'transparent',
    borderColor: '#191D29',
    height: 40,
    borderRadius: 6,

  }),
  menu: (styles, state) => ({
    ...styles,
    border: 'none',
    backgroundColor: '#191D29',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#191D29',
      borderColor: '#2C2F40',
      color: ((isFocused || isSelected) ? '#18C99D' : '#8A8FB5'),
      cursor: 'pointer',
      fontSize: 14
    };
  },
  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: '#636B91',
  }),
  indicatorSeparator: (styles, state) => ({
    ...styles,
    color: '#8A8FB5',
    backgroundColor: '#8A8FB5',
    display: 'none'
  }),
  input: styles => ({ fontSize: 14, ...styles }),
  placeholder: styles => ({ ...styles, color: '#636B91' }),
  singleValue: (styles, { data }) => ({ ...styles, fontWeight: 500, fontSize: 14, color: '#636B91' }),
  multiValue: styles => ({
    ...styles, padding: 1, borderRadius: 4, backgroundColor: "rgba(24, 201, 157, 0.10)", color: '#18C99D',
    ':hover': {
      backgroundColor: 'rgba(24, 201, 157, 0.25)',
    }
  }),
  multiValueLabel: styles => ({ ...styles, color: '#18C99D', fontWeight: 600 }),
  multiValueRemove: (styles, { isFocused }) => ({
    ...styles,
    ':hover': {
      backgroundColor: 'rgba(24, 201, 157, 0)',
    },
  }),
  valueContainer: styles => ({ ...styles, paddingRight: 0, border: 'none' })
};

export default CreateStreamPage;