import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import cx from 'classnames';
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle } from "../components/Modal";
import { Button } from "../components/Button";
import { useSelector } from "react-redux";
import { selectCurrentOrgId } from "../store/models/Organization";
import { selectCurrentUserId } from "../store/models/User";
import { selectCurrentProjectId } from "../store/models/Project";
import { createSelector } from "reselect";
import { Urls } from '../constants';

const selector = createSelector(
  selectCurrentOrgId,
  selectCurrentUserId,
  selectCurrentProjectId,
  (organizationId, currentUserId, projectId) => ({
    organizationId,
    currentUserId,
    projectId
  })
)

export const IncreaseQuotaModal = ({ onRequestClose, orgId, isBasic, isUpgraded, error, setIsUpgraded }) => {
  const { organizationId, currentUserId, projectId } = useSelector(state => selector(state));
  const history = useHistory();
  const notAuth = error === 'you are not authorized';

  const handleClose = () => {
    if (isUpgraded) setIsUpgraded(false);
    onRequestClose();
  }
  const onSendEmail = () => {
    window.open("mailto:support@thetaedgecloud.com" +
      "?subject=Increase%20Quota%20Request" +
      "&body=" +
      "%0A%0A%0A" +
      "User%20ID:%20" + currentUserId +
      "%0A" +
      "Organization%20ID:%20" + orgId +
      "%0A%0A");
  }

  const handleRedirect = () => {
    history.push(Urls.SETTINGS_BILLING);
    handleClose();
  }

  return (
    <Modal className={cx("IncreaseQuotaModal s")}
      onRequestClose={handleClose}>

      {!isUpgraded && <ModalHeader>
        <ModalTitle>{notAuth ? '' : (isBasic ? 'Increase Quota' : 'Contact us')}</ModalTitle>
      </ModalHeader>}

      <ModalContent>

        {!isUpgraded && <div className={"short-content"}>{notAuth ?
          "Please contact your organization's admin to increase your quota." :
          (isBasic ?
            'In order to increase your quota, please ensure a minimum purchase of $20 worth of credits.' :
            'In order to increase your quota, send us an email.')}
        </div>}
        {isUpgraded && <div className={"short-content color-green"}>
          Congratulations! Your organization has been upgraded to the Developer Plan.
        </div>}
        <div className={cx("error")}>{error && !notAuth && error}</div>

        <ModalActions>
          {isUpgraded ? <Button color={"green"} title={"Confirm"} onClick={handleClose} />
            : <Button color={"green"}
              onClick={notAuth ? handleClose : isBasic ? handleRedirect : onSendEmail}
              title={notAuth ? "Ok" : isBasic ? "Go to Billing" : "Contact support"} />}
          {!isUpgraded && <Button color={"transparent"} onClick={handleClose} title={"Close"} />}
        </ModalActions>

      </ModalContent>

    </Modal>);
}