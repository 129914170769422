import React from 'react';
import cx from 'classnames';

export const ShowcaseGallery = ({ images, title, onClick, name, entries }) => {

  const onImageSelected = (index) => {
    onClick(index, name)
  }

  const onEntrySelected = (index) => {
    onClick(index, name)
  }

  return (
    <div className={"ShowcaseGallery"}>
      {title && <div className={"ShowcaseGallery__Title"}>{title}</div>}
      {images ? <div className={"ShowcaseGallery__Images"}>
        {images.map((image, index) => (
          <div key={index}
            className={"ShowcaseGallery__Image"}
            onClick={(e) => onImageSelected(index)}>
            <img src={image} alt={name} />
          </div>
        ))}
      </div>
        : (
          <div className={"ShowcaseGallery__Entries"}>
            {entries.map((entry, index) => (
              <div key={index} className={"ShowcaseGallery__Entry"} onClick={(e) => onEntrySelected(index)}>
                {entry.label}
              </div>
            ))}
          </div>
        )}
    </div>
  )
}